import hashwallet, { LoginOption } from "hashwallet-js";
import { provider, CHILD_WINDOW_URL } from "../constants/chain";
import HashwalletConfig from "./useHashWalletConfig";
import useWalletAddressStore from "../store/useWalletAddressStore";
import useEmailStore from "../store/useEmailStore";
import { useAuth } from "./useAuth";

export const HASHWALLET_STORE_ROUTE_KEY = "hashwallet-js://route-string";
export const hashwalletJs = new hashwallet(provider, HashwalletConfig(), {
  loginOptions: [LoginOption.Google, LoginOption.Email],
  childWindowUrl: CHILD_WINDOW_URL,
  isAutoConnectOn: true,
});
hashwalletJs.setOnRedirect(() => {
  window.sessionStorage.setItem(
    HASHWALLET_STORE_ROUTE_KEY,
    `${location.pathname}${location.search}`
  );
  return true;
});

function useHashWallet() {
  const { getOtt } = useAuth();
  const { walletAddress, setWalletAddress } = useWalletAddressStore();
  const { email, setEmail } = useEmailStore();

  const hashWalletSignIn = async () => {
    try {
      await hashwalletJs.login();
      const wallet = hashwalletJs.getWallet();
      if (wallet === null || wallet.address === null) {
        throw new Error("hashWallet Get wallet failed");
      }
      const email = hashwalletJs.getUserInfo().email;
      if (email === null) {
        throw new Error("hashWallet Get email failed");
      }
      setWalletAddress(wallet.address);
      setEmail(email);
    } catch (error) {
      console.error(error);
      throw new Error("hashWallet Sign in failed", { cause: error });
    }
  };

  const hashWalletLogin = async () => {
    try {
      await hashwalletJs.login();
      const wallet = hashwalletJs.getWallet();
      if (!wallet) {
        throw new Error("Wallet is not found");
      }
      const ott = await getOtt();
      return { wallet, ott };
    } catch (error) {
      console.error(error);
      throw new Error("hashWallet Sign in failed", { cause: error });
    }
  };

  const hashWalletSignOut = async () => {
    try {
      await hashwalletJs.logout();
    } catch (error) {
      throw new Error("hashWallet Sign out failed", { cause: error });
    }
  };

  return {
    email,
    walletAddress,
    hashWalletSignIn,
    hashWalletSignOut,
    hashWalletLogin,
  };
}

export default useHashWallet;
