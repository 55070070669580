import { LoaderFunctionArgs } from "react-router-dom";

export type RegisterWalletLoaderType = {
  secret: string;
};
export const RegisterWalletLoader = ({
  request,
}: LoaderFunctionArgs): RegisterWalletLoaderType => {
  const searchParams = new URL(request.url).searchParams;
  return { secret: searchParams.get("secret") || "" };
};
