import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const emailState = atom<string>({
  key: "emailState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

const useEmailStore = () => {
  const [email, setEmail] = useRecoilState(emailState);

  return {
    email,
    setEmail,
  };
};

export default useEmailStore;
