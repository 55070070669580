import { Gender, Guests } from "../api/page/generated";

export const PREFECTURES = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export const ADULT_NUMBERS = ["0", "1", "2"];
export const CHILD_NUMBERS = ["0", "1"];
export const ARRIVAL_TIMES: string[] = [
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const GENDER = ["男", "女", "その他"];
export const GENDER_ENUM = [Gender.Male, Gender.Female, Gender.Etc];
export const GENDER_CONFIRMATION_MAP = ["男性", "女性", "その他"];

// guestsが存在しない場合のデフォルト値
export const defaultGuests: PartialGuests = {
  representative_name: "",
  representative_kana: "",
  gender: undefined, // Update the value to a valid Gender value
  postal_code: "",
  prefectures: "",
  address1: "",
  address2: "",
  telephone_number: "",
  email: "",
  user_number_adult: 0,
  user_number_child: 0,
  scheduled_arrival_time: "",
  notes: "",
  contract_address: "", // Add the missing property
  token_id: -1, // Add the missing property
};
// 全てのプロパティをオプションにしたGuests型
export type PartialGuests = Omit<Guests, "gender"> & { gender?: Gender };
