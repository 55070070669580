import "swiper/css";
import "swiper/css/pagination";
import "./MyMemberships.scss";
import { useEffect, useState } from "react";
import MyMembershipSwiper from "../MyMembershipSwiper/MyMembershipSwiper";

import { convertToJapaneseDuration, formatDate } from "../../utils";
import { Membership, getMetadata } from "../../loader/MyPageLoader";
import { getBalance } from "../../hooks/reservation";

export type MyMembershipsProps = {
  memberships: Membership;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setBalance: (balance: number) => void;
};

export default function MyMemberships({
  memberships,
  currentIndex,
  setCurrentIndex,
  setBalance,
}: MyMembershipsProps) {
  const imageUrls = memberships.map((d) => d.profile.image ?? "");
  const profile = memberships[currentIndex].profile;
  const name = profile.name;
  const [issueDate, setIssueDate] = useState<string | undefined>(undefined);
  const [numberOfNights, setNumberOfNights] = useState<string | undefined>(
    undefined
  );
  const tokenId = memberships[currentIndex].tokenId;

  useEffect(() => {
    async function fetchData() {
      if (!memberships[currentIndex].uri) {
        return;
      }
      const metadata = await getMetadata(memberships[currentIndex].uri);
      setIssueDate(
        metadata.attributes.find((attr) => attr.trait_type === "ISSUE DATE")
          ?.value
      );
      setNumberOfNights(
        metadata.attributes.find(
          (attr) => attr.trait_type === "NUMBER OF NIGHTS"
        )?.value
      );
      const balance = getBalance(metadata.attributes);
      if (balance) {
        setBalance(Number(balance));
      }
    }
    fetchData();
  }, [currentIndex]);

  const handleSlideChange = (index: number) => {
    setCurrentIndex(index);
  };
  return (
    <div className="mymemberships">
      <div className="spw375h667px-spw375h667px">
        <span className="spw375h667px-text12">
          <span>保有NFT</span>
        </span>
        <div className="spw375h667px-group121">
          <div className="spw375h667px-frame114">
            <MyMembershipSwiper
              imageUrls={imageUrls}
              handleSlideChange={handleSlideChange}
            />
          </div>
        </div>
        {tokenId != -1 && (
          <div className="spw375h667px-group148">
            <span className="spw375h667px-text54">{name}</span>
            <span className="spw375h667px-text58">
              <span>
                有効期間：{issueDate && formatDate(issueDate)}-2026.04.30
              </span>
              <br></br>
              <span>
                宿泊日数：
                {numberOfNights && convertToJapaneseDuration(numberOfNights)}
              </span>
              <br></br>
              <span>条　　件：1室2名朝食付</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
