import { ethers, BigNumber } from "ethers";
import { EXCHANGE_CONTRACT_ADDRESS } from "../constants/contracts.ts";
import { ExchangeAbi } from "../abi/Exchange.abi.ts";
import { provider } from "../constants/chain.ts";
import { useEffect, useState } from "react";

const fetchExchangeLatestEvents = async (
  contractAddress: string,
  tokenId: number,
  walletAddress: string
) => {
  const contract = new ethers.Contract(
    EXCHANGE_CONTRACT_ADDRESS,
    ExchangeAbi,
    provider
  );
  const sellRequestedFilter = contract.filters.SellRequested();
  const canceledSellFilter = contract.filters.CanceledSell();

  // 最新のブロック番号を取得
  const latestBlockNumber = await provider.getBlockNumber();

  // 過去のSellRequestedイベントを取得（最新1年分の範囲）
  const sellRequestedEvents = await contract.queryFilter(
    sellRequestedFilter,
    latestBlockNumber - 2102400,
    "latest"
  );

  // 過去のCanceledSellイベントを取得（最新1年分の範囲）
  const canceledSellEvents = await contract.queryFilter(
    canceledSellFilter,
    latestBlockNumber - 2102400,
    "latest"
  );

  // 両方のイベントをマージ
  const allEvents = [...sellRequestedEvents, ...canceledSellEvents];

  const relatedEvents = filterEvents(
    allEvents,
    walletAddress,
    contractAddress,
    tokenId.toString()
  );

  // タイムスタンプに基づいてイベントをソート
  const sortedEvents = relatedEvents.sort(
    (a, b) => b.blockNumber - a.blockNumber
  );

  // 最新のイベントを取得
  const latestEvent = sortedEvents.length > 0 ? sortedEvents[0] : null;
  console.log("latestEvent", latestEvent);

  // 最新のイベントを処理
  if (latestEvent?.event === "SellRequested") {
    return true;
  }
  return false;
};

export const filterEvents = (
  events: ethers.Event[],
  walletAddress: string,
  tokenAddress: string,
  tokenId: string
) => {
  return events.filter(
    (event) =>
      event.args?.seller.toLowerCase() === walletAddress.toLowerCase() &&
      event.args?.token === tokenAddress &&
      BigNumber.from(event.args?.tokenId).eq(BigNumber.from(tokenId))
  );
};

export function useEvent(
  contractAddress: string,
  tokenId: number,
  walletAddress: string
) {
  /// 2時流通中であるかどうか
  const [isSellRequeted, setSellRequested] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const isSellRequested = await fetchExchangeLatestEvents(
        contractAddress,
        tokenId,
        walletAddress
      );
      setSellRequested(isSellRequested);
    };
    fetch();
  }, [tokenId]);
  return { isSellRequeted };
}

export default useEvent;
