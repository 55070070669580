import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import usePageTracking from "../hooks/usePageTracking";
import { HASHWALLET_STORE_ROUTE_KEY } from "../hooks/useHashWallet.tsx";
import { useEffect } from "react";

export default function RootPage() {
  usePageTracking();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const routeString = window.sessionStorage.getItem(
      HASHWALLET_STORE_ROUTE_KEY
    );
    if (routeString) {
      window.sessionStorage.removeItem(HASHWALLET_STORE_ROUTE_KEY);
      navigate(routeString);
    }
  });

  // Headerを表示しないページのパスに対する正規表現
  const hideHeaderForPaths = [
    new RegExp("^/apply$"), // URLが '/apply' と完全に一致する場合
    new RegExp("^/wallet/register/.*"), // URLが '/wallet/register/' で始まり、その後に任意の文字が続く場合
    new RegExp("^/wallet/registration-completed$"), // URLが '/wallet/registration-completed' と完全に一致する場合
  ];

  const shouldHideHeader = hideHeaderForPaths.some((regex) =>
    regex.test(location.pathname)
  );

  return (
    <>
      {!shouldHideHeader && <Header />}
      <Outlet /> {/* 子ルートがレンダリングされる場所 */}
      <Footer />
    </>
  );
}
