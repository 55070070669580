import { LoaderFunctionArgs } from "react-router-dom";
import { Guests, GuestsApi, ReservationStatus } from "../api/page/generated";
import { Status, fetchOwner, judgeStatus } from "../hooks/status";
import { hashwalletConnector } from "../hooks/connector";
import { connect, getAccount } from "@wagmi/core";
import { config } from "../constants/chain";
import { fetchTokenUri, getMetadata } from "./NftLoader";

export type CheckinConfirmationLoaderType = {
  contractAddress: string;
  tokenId: number;
  savedGuests?: Guests;
  status: Status;
  reservationStatus?: ReservationStatus;
};

export const getGuests = async (contractAddress: string, tokenId: string) => {
  const res = await new GuestsApi().contractAddressTokenIdGuestsGet(
    contractAddress,
    Number(tokenId)
  );
  return res.data;
};

export const CheckinConfirmationLoader = async ({
  params,
}: LoaderFunctionArgs): Promise<CheckinConfirmationLoaderType> => {
  const { contractAddress, tokenId } = params;
  if (!contractAddress) {
    throw new Error("contractAddress is required");
  }
  if (!tokenId) {
    throw new Error("tokenId is required");
  }
  await connect(config, { connector: hashwalletConnector() });
  const walletAddress = getAccount(config).address ?? "";
  if (!walletAddress) {
    throw new Error("walletAddress is not found");
  }
  const owner = await fetchOwner(contractAddress, tokenId);
  if (!owner) {
    throw new Error("owner is not found");
  }
  try {
    const tokenUri = await fetchTokenUri(contractAddress, tokenId);
    const metadata = await getMetadata(tokenUri);
    const status = await judgeStatus(
      contractAddress,
      String(tokenId),
      metadata?.attributes,
      walletAddress,
      owner
    );
    try {
      const data = await getGuests(contractAddress, tokenId);
      return {
        contractAddress: contractAddress,
        tokenId: Number(params.tokenId),
        savedGuests: data.guests,
        status: status,
        reservationStatus: data.status,
      };
    } catch (error) {
      return {
        contractAddress: contractAddress,
        tokenId: Number(params.tokenId),
        status: status,
      };
    }
  } catch (error) {
    throw new Error("CheckinConfirmationLoader failed", { cause: error });
  }
};
