/* tslint:disable */
/* eslint-disable */
/**
 * Wealth APIs
 * WealthシステムのAPI定義書
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ContractAddressTokenIdGuestsGet200Response
 */
export interface ContractAddressTokenIdGuestsGet200Response {
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ContractAddressTokenIdGuestsGet200Response
     */
    'status': ReservationStatus;
    /**
     * 
     * @type {Guests}
     * @memberof ContractAddressTokenIdGuestsGet200Response
     */
    'guests': Guests;
}


/**
 * 性別. `male`: 男性, `female`: 女性, `etc`: その他
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'male',
    Female: 'female',
    Etc: 'etc'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GetCheckinNftsContractAddressTokenId200Response
 */
export interface GetCheckinNftsContractAddressTokenId200Response {
    /**
     * 
     * @type {Array<MembershipTokenContractMapping>}
     * @memberof GetCheckinNftsContractAddressTokenId200Response
     */
    'checkin_nfts': Array<MembershipTokenContractMapping>;
}
/**
 * 
 * @export
 * @interface GetOtt200Response
 */
export interface GetOtt200Response {
    /**
     * ワンタイムトークン
     * @type {string}
     * @memberof GetOtt200Response
     */
    'ott': string;
}
/**
 * 宿泊者情報
 * @export
 * @interface Guests
 */
export interface Guests {
    /**
     * 宿泊者ID(ウォレットアドレス_コントラクトアドレス_トークンID)
     * @type {string}
     * @memberof Guests
     */
    'guests_id'?: string;
    /**
     * ウォレットアドレス。0xから始まる
     * @type {string}
     * @memberof Guests
     */
    'wallet_address'?: string;
    /**
     * ブロックチェーン上のアドレス
     * @type {string}
     * @memberof Guests
     */
    'contract_address': string;
    /**
     * トークンID
     * @type {number}
     * @memberof Guests
     */
    'token_id': number;
    /**
     * 代表者名（漢字）
     * @type {string}
     * @memberof Guests
     */
    'representative_name': string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof Guests
     */
    'representative_kana': string;
    /**
     * 
     * @type {Gender}
     * @memberof Guests
     */
    'gender': Gender;
    /**
     * 郵便番号
     * @type {string}
     * @memberof Guests
     */
    'postal_code': string;
    /**
     * 都道府県
     * @type {string}
     * @memberof Guests
     */
    'prefectures': string;
    /**
     * 住所１
     * @type {string}
     * @memberof Guests
     */
    'address1': string;
    /**
     * 住所２
     * @type {string}
     * @memberof Guests
     */
    'address2': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Guests
     */
    'telephone_number': string;
    /**
     * eメールアドレス
     * @type {string}
     * @memberof Guests
     */
    'email': string;
    /**
     * 利用人数（大人）
     * @type {number}
     * @memberof Guests
     */
    'user_number_adult': number;
    /**
     * 利用人数（こども）
     * @type {number}
     * @memberof Guests
     */
    'user_number_child': number;
    /**
     * 到着時間
     * @type {string}
     * @memberof Guests
     */
    'scheduled_arrival_time': string;
    /**
     * 備考
     * @type {string}
     * @memberof Guests
     */
    'notes'?: string;
}


/**
 * 宿泊者情報
 * @export
 * @interface GuestsMetadata
 */
export interface GuestsMetadata {
    /**
     * Unix タイムスタンプ（秒）
     * @type {number}
     * @memberof GuestsMetadata
     */
    'issue_at': number;
    /**
     * ホテル
     * @type {string}
     * @memberof GuestsMetadata
     */
    'hotel': string;
    /**
     * プラン
     * @type {string}
     * @memberof GuestsMetadata
     */
    'plan': string;
    /**
     * チェックイン日（年）
     * @type {number}
     * @memberof GuestsMetadata
     */
    'checkin_year': number;
    /**
     * チェックイン日（月）
     * @type {number}
     * @memberof GuestsMetadata
     */
    'checkin_month': number;
    /**
     * チェックイン日（日）
     * @type {number}
     * @memberof GuestsMetadata
     */
    'checkin_day': number;
    /**
     * 宿泊日数
     * @type {number}
     * @memberof GuestsMetadata
     */
    'checkin_number_of_nights': number;
}
/**
 * トークンとコントラクトのマッピング情報
 * @export
 * @interface MembershipTokenContractMapping
 */
export interface MembershipTokenContractMapping {
    /**
     * トークンID
     * @type {number}
     * @memberof MembershipTokenContractMapping
     */
    'token_id': number;
    /**
     * ウォレットアドレス。0xから始まる
     * @type {string}
     * @memberof MembershipTokenContractMapping
     */
    'contract_address': string;
}
/**
 * 
 * @export
 * @interface PostAccountSigninWalletAddressRequest
 */
export interface PostAccountSigninWalletAddressRequest {
    /**
     * siweのメッセージ
     * @type {string}
     * @memberof PostAccountSigninWalletAddressRequest
     */
    'message': string;
    /**
     * 0xから始まる署名（r, s ,v）
     * @type {string}
     * @memberof PostAccountSigninWalletAddressRequest
     */
    'signature': string;
}
/**
 * 
 * @export
 * @interface PostCancelReservationRequest
 */
export interface PostCancelReservationRequest {
    /**
     * ブロックチェーン上のアドレス
     * @type {string}
     * @memberof PostCancelReservationRequest
     */
    'contract_address': string;
    /**
     * トークンID
     * @type {number}
     * @memberof PostCancelReservationRequest
     */
    'token_id': number;
}
/**
 * 
 * @export
 * @interface PostReservation200Response
 */
export interface PostReservation200Response {
    /**
     * 
     * @type {Guests}
     * @memberof PostReservation200Response
     */
    'guests': Guests;
}
/**
 * 
 * @export
 * @interface PostReservationRequest
 */
export interface PostReservationRequest {
    /**
     * 
     * @type {ReservationType}
     * @memberof PostReservationRequest
     */
    'reservation_type': ReservationType;
    /**
     * 
     * @type {Guests}
     * @memberof PostReservationRequest
     */
    'guests': Guests;
    /**
     * 
     * @type {GuestsMetadata}
     * @memberof PostReservationRequest
     */
    'metadata': GuestsMetadata;
}


/**
 * 予約ステータス. `new`: 新規予約済, `change`: 予約変更済 `canceling`: キャンセル中 `cancel_failed`: キャンセル失敗
 * @export
 * @enum {string}
 */

export const ReservationStatus = {
    New: 'new',
    Change: 'change',
    Canceling: 'canceling',
    CancelFailed: 'cancel_failed'
} as const;

export type ReservationStatus = typeof ReservationStatus[keyof typeof ReservationStatus];


/**
 * 予約種別. `new`: 新規予約, `change`: 予約変更
 * @export
 * @enum {string}
 */

export const ReservationType = {
    New: 'new',
    Change: 'change'
} as const;

export type ReservationType = typeof ReservationType[keyof typeof ReservationType];



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ワンタイムトークンを取得する
         * @summary Get one time token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtt: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ott`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ウォレットアドレスを署名で検証し（公開鍵は署名から導出）、署名が適切なものであればユーザをサインインさせる
         * @summary Sign in by wallet address
         * @param {PostAccountSigninWalletAddressRequest} [postAccountSigninWalletAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountSigninWalletAddress: async (postAccountSigninWalletAddressRequest?: PostAccountSigninWalletAddressRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAccountSigninWalletAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * システムからサインアウトする
         * @summary Sign out from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountSignout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * ワンタイムトークンを取得する
         * @summary Get one time token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtt(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOtt200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtt(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getOtt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ウォレットアドレスを署名で検証し（公開鍵は署名から導出）、署名が適切なものであればユーザをサインインさせる
         * @summary Sign in by wallet address
         * @param {PostAccountSigninWalletAddressRequest} [postAccountSigninWalletAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest?: PostAccountSigninWalletAddressRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAccountSigninWalletAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * システムからサインアウトする
         * @summary Sign out from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountSignout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountSignout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAccountSignout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * ワンタイムトークンを取得する
         * @summary Get one time token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtt(options?: any): AxiosPromise<GetOtt200Response> {
            return localVarFp.getOtt(options).then((request) => request(axios, basePath));
        },
        /**
         * ウォレットアドレスを署名で検証し（公開鍵は署名から導出）、署名が適切なものであればユーザをサインインさせる
         * @summary Sign in by wallet address
         * @param {PostAccountSigninWalletAddressRequest} [postAccountSigninWalletAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest?: PostAccountSigninWalletAddressRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * システムからサインアウトする
         * @summary Sign out from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountSignout(options?: any): AxiosPromise<void> {
            return localVarFp.postAccountSignout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * ワンタイムトークンを取得する
     * @summary Get one time token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getOtt(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getOtt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ウォレットアドレスを署名で検証し（公開鍵は署名から導出）、署名が適切なものであればユーザをサインインさせる
     * @summary Sign in by wallet address
     * @param {PostAccountSigninWalletAddressRequest} [postAccountSigninWalletAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest?: PostAccountSigninWalletAddressRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAccountSigninWalletAddress(postAccountSigninWalletAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * システムからサインアウトする
     * @summary Sign out from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAccountSignout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAccountSignout(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuestsApi - axios parameter creator
 * @export
 */
export const GuestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get guests info
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractAddressTokenIdGuestsGet: async (contractAddress: string, tokenId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractAddress' is not null or undefined
            assertParamExists('contractAddressTokenIdGuestsGet', 'contractAddress', contractAddress)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('contractAddressTokenIdGuestsGet', 'tokenId', tokenId)
            const localVarPath = `/{contractAddress}/{tokenId}/guests`
                .replace(`{${"contractAddress"}}`, encodeURIComponent(String(contractAddress)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 宿泊予約情報をキャンセルする
         * @summary Cancel guests reservation
         * @param {PostCancelReservationRequest} [postCancelReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCancelReservation: async (postCancelReservationRequest?: PostCancelReservationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cancel-reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCancelReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 宿泊予約情報を登録または更新する
         * @summary Reservation guests
         * @param {PostReservationRequest} [postReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReservation: async (postReservationRequest?: PostReservationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestsApi - functional programming interface
 * @export
 */
export const GuestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get guests info
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractAddressTokenIdGuestsGet(contractAddress: string, tokenId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAddressTokenIdGuestsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractAddressTokenIdGuestsGet(contractAddress, tokenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestsApi.contractAddressTokenIdGuestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 宿泊予約情報をキャンセルする
         * @summary Cancel guests reservation
         * @param {PostCancelReservationRequest} [postCancelReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCancelReservation(postCancelReservationRequest?: PostCancelReservationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCancelReservation(postCancelReservationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestsApi.postCancelReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 宿泊予約情報を登録または更新する
         * @summary Reservation guests
         * @param {PostReservationRequest} [postReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReservation(postReservationRequest?: PostReservationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostReservation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReservation(postReservationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuestsApi.postReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuestsApi - factory interface
 * @export
 */
export const GuestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get guests info
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractAddressTokenIdGuestsGet(contractAddress: string, tokenId: number, options?: any): AxiosPromise<ContractAddressTokenIdGuestsGet200Response> {
            return localVarFp.contractAddressTokenIdGuestsGet(contractAddress, tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 宿泊予約情報をキャンセルする
         * @summary Cancel guests reservation
         * @param {PostCancelReservationRequest} [postCancelReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCancelReservation(postCancelReservationRequest?: PostCancelReservationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postCancelReservation(postCancelReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 宿泊予約情報を登録または更新する
         * @summary Reservation guests
         * @param {PostReservationRequest} [postReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReservation(postReservationRequest?: PostReservationRequest, options?: any): AxiosPromise<PostReservation200Response> {
            return localVarFp.postReservation(postReservationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuestsApi - object-oriented interface
 * @export
 * @class GuestsApi
 * @extends {BaseAPI}
 */
export class GuestsApi extends BaseAPI {
    /**
     * 
     * @summary Get guests info
     * @param {string} contractAddress NFTのコントラクトアドレス
     * @param {number} tokenId NFTのトークンid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public contractAddressTokenIdGuestsGet(contractAddress: string, tokenId: number, options?: RawAxiosRequestConfig) {
        return GuestsApiFp(this.configuration).contractAddressTokenIdGuestsGet(contractAddress, tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 宿泊予約情報をキャンセルする
     * @summary Cancel guests reservation
     * @param {PostCancelReservationRequest} [postCancelReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public postCancelReservation(postCancelReservationRequest?: PostCancelReservationRequest, options?: RawAxiosRequestConfig) {
        return GuestsApiFp(this.configuration).postCancelReservation(postCancelReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 宿泊予約情報を登録または更新する
     * @summary Reservation guests
     * @param {PostReservationRequest} [postReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public postReservation(postReservationRequest?: PostReservationRequest, options?: RawAxiosRequestConfig) {
        return GuestsApiFp(this.configuration).postReservation(postReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NFTApi - axios parameter creator
 * @export
 */
export const NFTApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メンバーシップに紐づくチェックインNFT情報を取得する
         * @summary Get check-in nfts info by membership contract address token id
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckinNftsContractAddressTokenId: async (contractAddress: string, tokenId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractAddress' is not null or undefined
            assertParamExists('getCheckinNftsContractAddressTokenId', 'contractAddress', contractAddress)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('getCheckinNftsContractAddressTokenId', 'tokenId', tokenId)
            const localVarPath = `/nfts/checkin/{contractAddress}/{tokenId}`
                .replace(`{${"contractAddress"}}`, encodeURIComponent(String(contractAddress)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NFTApi - functional programming interface
 * @export
 */
export const NFTApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NFTApiAxiosParamCreator(configuration)
    return {
        /**
         * メンバーシップに紐づくチェックインNFT情報を取得する
         * @summary Get check-in nfts info by membership contract address token id
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCheckinNftsContractAddressTokenId(contractAddress: string, tokenId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCheckinNftsContractAddressTokenId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCheckinNftsContractAddressTokenId(contractAddress, tokenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NFTApi.getCheckinNftsContractAddressTokenId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NFTApi - factory interface
 * @export
 */
export const NFTApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NFTApiFp(configuration)
    return {
        /**
         * メンバーシップに紐づくチェックインNFT情報を取得する
         * @summary Get check-in nfts info by membership contract address token id
         * @param {string} contractAddress NFTのコントラクトアドレス
         * @param {number} tokenId NFTのトークンid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckinNftsContractAddressTokenId(contractAddress: string, tokenId: number, options?: any): AxiosPromise<GetCheckinNftsContractAddressTokenId200Response> {
            return localVarFp.getCheckinNftsContractAddressTokenId(contractAddress, tokenId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NFTApi - object-oriented interface
 * @export
 * @class NFTApi
 * @extends {BaseAPI}
 */
export class NFTApi extends BaseAPI {
    /**
     * メンバーシップに紐づくチェックインNFT情報を取得する
     * @summary Get check-in nfts info by membership contract address token id
     * @param {string} contractAddress NFTのコントラクトアドレス
     * @param {number} tokenId NFTのトークンid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFTApi
     */
    public getCheckinNftsContractAddressTokenId(contractAddress: string, tokenId: number, options?: RawAxiosRequestConfig) {
        return NFTApiFp(this.configuration).getCheckinNftsContractAddressTokenId(contractAddress, tokenId, options).then((request) => request(this.axios, this.basePath));
    }
}



