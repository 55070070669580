// useIntersectionObserver.js
import { RefObject, useEffect, useState } from "react";

function useIntersectionObserver(
  ref: RefObject<Element>,
  options?: IntersectionObserverInit
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // 要素がビューポートに入ったら isVisible を true に設定し、
      // それ以降は状態を更新しない
      if (entry.isIntersecting) {
        setIsVisible(true);
        // もし要素が一度表示されたら、それ以上observerの更新を行わない
        observer.unobserve(entry.target);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isVisible;
}

export default useIntersectionObserver;
