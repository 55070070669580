import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Marker from "../Marker/Marker";
import { mapApiKey, mapPosition } from "../../constants/googlemap";
import Map from "../Map/Map";

function GoogleMap() {
  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Wrapper apiKey={mapApiKey} render={render}>
      <Map
        style={{ width: "100%", aspectRatio: "16 / 9" }}
        center={mapPosition}
        zoom={16}
      >
        <Marker position={mapPosition} />
      </Map>
    </Wrapper>
  );
}

export default GoogleMap;
