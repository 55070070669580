import { connect, getAccount } from "@wagmi/core";
import { DefaultApi } from "../api/explorer";
import { LoaderFunctionArgs } from "react-router-dom";
import { hashwalletConnector } from "../hooks/connector";
import { config } from "../constants/chain";
import { Status, fetchOwner, judgeStatus } from "../hooks/status";
import { getGuests } from "./CheckinConfirmationLoader";
import { ReservationStatus } from "../api/page/generated";
export type NFTMetadata = {
  image: string;
  image_data: string;
  external_url: string;
  description: string;
  name: string;
  attributes: Attribute[];
};
export type Attribute = {
  trait_type: string;
  value: string;
};
export type NftLoaderType = {
  metadata: NFTMetadata;
  contractAddress: string;
  tokenId: number;
  walletAddress: string;
  owner: string;
  status: Status;
  reservationStatus?: ReservationStatus;
};

export const getMetadata = async (tokenURI: string) => {
  const metadata = await fetch(tokenURI);
  const _metadata = (await metadata.json()) as NFTMetadata;
  return _metadata;
};

export const fetchTokenUri = async (
  contractAddress: string,
  tokenId: string
) => {
  const tokenInfo = await new DefaultApi().nfttokeninfoPost({
    Contract: contractAddress,
    Token: tokenId,
  });
  if (!tokenInfo.data.Uri) {
    throw new Error("Token URI not found");
  }
  return tokenInfo.data.Uri;
};

export const NftLoader = async ({
  params,
}: LoaderFunctionArgs): Promise<NftLoaderType> => {
  const { contractAddress, tokenId } = params;
  if (!contractAddress) {
    throw new Error("contractAddress is required");
  }
  if (!tokenId) {
    throw new Error("tokenId is required");
  }
  await connect(config, { connector: hashwalletConnector() });
  const walletAddress = getAccount(config).address ?? "";
  if (!walletAddress) {
    throw new Error("walletAddress is not found");
  }
  const owner = await fetchOwner(contractAddress, tokenId);
  if (!owner) {
    throw new Error("owner is not found");
  }
  try {
    const tokenUri = await fetchTokenUri(contractAddress, tokenId);
    const metadata = await getMetadata(tokenUri);
    const status = await judgeStatus(
      contractAddress,
      String(tokenId),
      metadata?.attributes,
      walletAddress,
      owner
    );
    if (
      status === Status.BOOKED_GRAYED_OUT ||
      status === Status.EXPIRED ||
      status === Status.TRANSFERRED
    ) {
      throw new Error("you don't have permission to access this NFT");
    }
    try {
      const data = await getGuests(contractAddress, tokenId);
      return {
        metadata: metadata,
        contractAddress: contractAddress,
        tokenId: Number(tokenId),
        walletAddress: walletAddress,
        owner: owner,
        status: status,
        reservationStatus: data.status,
      };
    } catch (error) {
      console.error(error);
      return {
        metadata: metadata,
        contractAddress: contractAddress,
        tokenId: Number(tokenId),
        walletAddress: walletAddress,
        owner: owner,
        status: status,
      };
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch NFT metadata", { cause: error });
  }
};
