import { atom, useRecoilState, useResetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const walletAddressState = atom<string>({
  key: "walletAddressState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

const useWalletAddressStore = () => {
  const [walletAddress, setWalletAddress] = useRecoilState(walletAddressState);
  const resetWalletAddress = useResetRecoilState(walletAddressState);

  return {
    walletAddress,
    setWalletAddress,
    resetWalletAddress,
  };
};

export default useWalletAddressStore;
