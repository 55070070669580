import { Chain, http } from "viem";
import { ethers } from "ethers";
import { createConfig } from "wagmi";
import { getPublicClient } from "@wagmi/core";
import { hashwalletConnector } from "../hooks/connector";

export const palette = {
  id: import.meta.env.VITE_CHAIN_ID,
  name: "Palette",
  nativeCurrency: {
    decimals: 18,
    name: "Palette",
    symbol: "PLT",
  },
  rpcUrls: {
    public: { http: [import.meta.env.VITE_PALETTE_JSON_RPC] },
    default: { http: [import.meta.env.VITE_PALETTE_JSON_RPC] },
  },
} as const satisfies Chain;

export const provider = new ethers.providers.JsonRpcProvider(
  import.meta.env.VITE_PALETTE_JSON_RPC
);

export const config = createConfig({
  chains: [palette],
  transports: {
    [palette.id]: http(),
  },
  connectors: [
    hashwalletConnector(),
  ],
});
export const client = getPublicClient(config);

export const isEqualWalletAddress = (a: string, b: string) =>
  a.toLowerCase() === b.toLowerCase();
export const KEYCLOAK_ORIGIN = import.meta.env.VITE_KEYCLOAK_ORIGIN;
export const CHILD_WINDOW_URL = KEYCLOAK_ORIGIN + "/realms/hashwallet/login-actions/authenticate";