import { useEffect } from "react";

interface HiddenHubSpotFormProps {
  email: string;
  walletAddress: string;
  secret: string;
  portalId: string;
  formId: string;
}

function HiddenHubSpotForm({
  email,
  walletAddress,
  secret,
  portalId,
  formId,
}: HiddenHubSpotFormProps) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          onFormReady: function ($form: HTMLFormElement) {
            // フォームを非表示にする
            $form.style.display = "none";

            // フォームフィールドに値を設定する
            const emailField = $form.querySelector(
              'input[name="email"]'
            ) as HTMLInputElement;
            const walletField = $form.querySelector(
              'input[name="wallet"]'
            ) as HTMLInputElement;
            const secretField = $form.querySelector(
              'input[name="secret"]'
            ) as HTMLInputElement;
            if (emailField) emailField.value = email;
            if (walletField) walletField.value = walletAddress;
            if (secretField) secretField.value = secret;

            $form.submit();
          },
        });
      }
    });

    // Cleanup function
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [email, walletAddress, portalId, formId]);

  return <></>;
}

export default HiddenHubSpotForm;
