/* tslint:disable */
/* eslint-disable */
/**
 * Palette Explorer API
 * Palette Explorer API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from "./base";

/**
 *
 * @export
 * @interface AllNFTsOfUser
 */
export interface AllNFTsOfUser {
  /**
   *
   * @type {number}
   * @memberof AllNFTsOfUser
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof AllNFTsOfUser
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof AllNFTsOfUser
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof AllNFTsOfUser
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<NFTOfUser>}
   * @memberof AllNFTsOfUser
   */
  UserNFTs?: Array<NFTOfUser>;
}
/**
 *
 * @export
 * @interface Allerc1155sofuserPostRequest
 */
export interface Allerc1155sofuserPostRequest {
  /**
   *
   * @type {string}
   * @memberof Allerc1155sofuserPostRequest
   */
  Owner?: string;
  /**
   *
   * @type {number}
   * @memberof Allerc1155sofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Allerc1155sofuserPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface AllnftsholdersofuserPostRequest
 */
export interface AllnftsholdersofuserPostRequest {
  /**
   *
   * @type {string}
   * @memberof AllnftsholdersofuserPostRequest
   */
  Owner?: string;
  /**
   *
   * @type {number}
   * @memberof AllnftsholdersofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof AllnftsholdersofuserPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface Block
 */
export interface Block {
  /**
   *
   * @type {string}
   * @memberof Block
   */
  Hash?: string;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  GasLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  Size?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  Validators?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  GasUsed?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  Difficulty?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  Number?: number;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  ParentHash?: string;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  ReceiptHash?: string;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  TxHash?: string;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  TxNumber?: number;
  /**
   *
   * @type {number}
   * @memberof Block
   */
  Time?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Block
   */
  Transactions?: Array<string>;
}
/**
 *
 * @export
 * @interface BlockbyhashPostRequest
 */
export interface BlockbyhashPostRequest {
  /**
   * トランザクションハッシュの値。0xから始まる
   * @type {string}
   * @memberof BlockbyhashPostRequest
   */
  Hash?: string;
}
/**
 *
 * @export
 * @interface BlockbynumberPostRequest
 */
export interface BlockbynumberPostRequest {
  /**
   *
   * @type {number}
   * @memberof BlockbynumberPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof BlockbynumberPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface BlockbytimestampPostRequest
 */
export interface BlockbytimestampPostRequest {
  /**
   * the integer representing the Unix timestamp in seconds.
   * @type {number}
   * @memberof BlockbytimestampPostRequest
   */
  Timestamp?: number;
  /**
   *
   * @type {Closest}
   * @memberof BlockbytimestampPostRequest
   */
  Closest?: Closest;
}

/**
 *
 * @export
 * @interface Blocks
 */
export interface Blocks {
  /**
   *
   * @type {number}
   * @memberof Blocks
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Blocks
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Blocks
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Blocks
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<Block>}
   * @memberof Blocks
   */
  Blocks?: Array<Block>;
}
/**
 *
 * @export
 * @interface Chain
 */
export interface Chain {
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof Chain
   */
  Name?: string;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  Height?: number;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  StakeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  LastReward?: number;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  MintPrice?: number;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  RewardPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof Chain
   */
  GasFee?: number;
}
/**
 * the closest available block to the provided timestamp, either before or after
 * @export
 * @enum {string}
 */

export const Closest = {
  Before: "before",
  After: "after",
} as const;

export type Closest = typeof Closest[keyof typeof Closest];

/**
 *
 * @export
 * @interface ContractInfo
 */
export interface ContractInfo {
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof ContractInfo
   */
  Type?: number;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Symbol?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Uri?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  Site?: string;
  /**
   *
   * @type {number}
   * @memberof ContractInfo
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof ContractInfo
   */
  TotalSupply?: number;
  /**
   *
   * @type {number}
   * @memberof ContractInfo
   */
  AddressNum?: number;
  /**
   *
   * @type {number}
   * @memberof ContractInfo
   */
  TransferNum?: number;
  /**
   *
   * @type {string}
   * @memberof ContractInfo
   */
  VerificationId?: string;
}
/**
 *
 * @export
 * @interface ContractVerification
 */
export interface ContractVerification {
  /**
   *
   * @type {string}
   * @memberof ContractVerification
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ContractVerification
   */
  Address?: string;
  /**
   *
   * @type {string}
   * @memberof ContractVerification
   */
  CompilerVersion?: string;
  /**
   *
   * @type {number}
   * @memberof ContractVerification
   */
  Status?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ContractVerification
   */
  SourceCodes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ContractVerification
   */
  OptimizationUsed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ContractVerification
   */
  Runs?: number;
  /**
   *
   * @type {string}
   * @memberof ContractVerification
   */
  EvmVersion?: string;
  /**
   *
   * @type {Array<Library>}
   * @memberof ContractVerification
   */
  Libraries?: Array<Library>;
}
/**
 *
 * @export
 * @interface CreatedAddressDayInfo
 */
export interface CreatedAddressDayInfo {
  /**
   *
   * @type {number}
   * @memberof CreatedAddressDayInfo
   */
  Day?: number;
  /**
   *
   * @type {number}
   * @memberof CreatedAddressDayInfo
   */
  Amount?: number;
}
/**
 *
 * @export
 * @interface CreatedAddressMonthInfo
 */
export interface CreatedAddressMonthInfo {
  /**
   *
   * @type {number}
   * @memberof CreatedAddressMonthInfo
   */
  Month?: number;
  /**
   *
   * @type {Array<CreatedAddressDayInfo>}
   * @memberof CreatedAddressMonthInfo
   */
  DayInfo?: Array<CreatedAddressDayInfo>;
}
/**
 *
 * @export
 * @interface ERC1155Token
 */
export interface ERC1155Token {
  /**
   *
   * @type {string}
   * @memberof ERC1155Token
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof ERC1155Token
   */
  Token?: number;
  /**
   *
   * @type {string}
   * @memberof ERC1155Token
   */
  Uri?: string;
  /**
   *
   * @type {Profile}
   * @memberof ERC1155Token
   */
  Profile?: Profile;
}
/**
 *
 * @export
 * @interface ERC1155TokenTransactions
 */
export interface ERC1155TokenTransactions {
  /**
   *
   * @type {number}
   * @memberof ERC1155TokenTransactions
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155TokenTransactions
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155TokenTransactions
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155TokenTransactions
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<TransactionDetail>}
   * @memberof ERC1155TokenTransactions
   */
  TransactionDetails?: Array<TransactionDetail>;
}
/**
 *
 * @export
 * @interface ERC1155Tokensofuser
 */
export interface ERC1155Tokensofuser {
  /**
   *
   * @type {number}
   * @memberof ERC1155Tokensofuser
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Tokensofuser
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Tokensofuser
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Tokensofuser
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<ERC1155Token>}
   * @memberof ERC1155Tokensofuser
   */
  ERC1155TokenInfos?: Array<ERC1155Token>;
}
/**
 *
 * @export
 * @interface ERC1155User
 */
export interface ERC1155User {
  /**
   *
   * @type {string}
   * @memberof ERC1155User
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof ERC1155User
   */
  Owner?: string;
  /**
   *
   * @type {number}
   * @memberof ERC1155User
   */
  TokenNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ERC1155User
   */
  Percent?: string;
}
/**
 *
 * @export
 * @interface ERC1155Users
 */
export interface ERC1155Users {
  /**
   *
   * @type {number}
   * @memberof ERC1155Users
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Users
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Users
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof ERC1155Users
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<ERC1155User>}
   * @memberof ERC1155Users
   */
  ERC1155Users?: Array<ERC1155User>;
}
/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {number}
   * @memberof Event
   */
  Number?: number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  EventId?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Topic1?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Topic2?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Topic3?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Topic4?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Data?: string;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  Time?: number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  TransactionHash?: string;
}
/**
 *
 * @export
 * @interface Events
 */
export interface Events {
  /**
   *
   * @type {number}
   * @memberof Events
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Events
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Events
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Events
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<Event>}
   * @memberof Events
   */
  Events?: Array<Event>;
}
/**
 *
 * @export
 * @interface Info
 */
export interface Info {
  /**
   *
   * @type {string}
   * @memberof Info
   */
  Version?: string;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  URL?: string;
}
/**
 *
 * @export
 * @interface Library
 */
export interface Library {
  /**
   *
   * @type {string}
   * @memberof Library
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof Library
   */
  Address?: string;
}
/**
 *
 * @export
 * @interface NFT
 */
export interface NFT {
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof NFT
   */
  Type?: number;
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Symbol?: string;
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Uri?: string;
  /**
   *
   * @type {string}
   * @memberof NFT
   */
  Site?: string;
  /**
   *
   * @type {number}
   * @memberof NFT
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof NFT
   */
  TotalSupply?: number;
  /**
   *
   * @type {number}
   * @memberof NFT
   */
  AddressNum?: number;
  /**
   *
   * @type {number}
   * @memberof NFT
   */
  TransferNum?: number;
}
/**
 *
 * @export
 * @interface NFTOfUser
 */
export interface NFTOfUser {
  /**
   *
   * @type {string}
   * @memberof NFTOfUser
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof NFTOfUser
   */
  Token?: number;
  /**
   *
   * @type {string}
   * @memberof NFTOfUser
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof NFTOfUser
   */
  Uri?: string;
  /**
   *
   * @type {Profile}
   * @memberof NFTOfUser
   */
  Profile?: Profile;
  /**
   *
   * @type {ContractInfo}
   * @memberof NFTOfUser
   */
  ContractInfo?: ContractInfo;
}
/**
 *
 * @export
 * @interface NFTToken
 */
export interface NFTToken {
  /**
   *
   * @type {string}
   * @memberof NFTToken
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof NFTToken
   */
  Token?: number;
  /**
   *
   * @type {string}
   * @memberof NFTToken
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof NFTToken
   */
  Uri?: string;
  /**
   *
   * @type {Profile}
   * @memberof NFTToken
   */
  Profile?: Profile;
}
/**
 *
 * @export
 * @interface NFTTokenTransactions
 */
export interface NFTTokenTransactions {
  /**
   *
   * @type {number}
   * @memberof NFTTokenTransactions
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokenTransactions
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokenTransactions
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokenTransactions
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<TransactionDetail>}
   * @memberof NFTTokenTransactions
   */
  TransactionDetails?: Array<TransactionDetail>;
}
/**
 *
 * @export
 * @interface NFTTokens
 */
export interface NFTTokens {
  /**
   *
   * @type {number}
   * @memberof NFTTokens
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokens
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokens
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof NFTTokens
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<NFTToken>}
   * @memberof NFTTokens
   */
  NFTTokenInfos?: Array<NFTToken>;
}
/**
 *
 * @export
 * @interface NFTUser
 */
export interface NFTUser {
  /**
   *
   * @type {string}
   * @memberof NFTUser
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof NFTUser
   */
  Owner?: string;
  /**
   *
   * @type {number}
   * @memberof NFTUser
   */
  TokenNumber?: number;
  /**
   *
   * @type {string}
   * @memberof NFTUser
   */
  Percent?: string;
}
/**
 *
 * @export
 * @interface NFTUsers
 */
export interface NFTUsers {
  /**
   *
   * @type {number}
   * @memberof NFTUsers
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof NFTUsers
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NFTUsers
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof NFTUsers
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<NFTUser>}
   * @memberof NFTUsers
   */
  NFTUsers?: Array<NFTUser>;
}
/**
 *
 * @export
 * @interface NftPostRequest
 */
export interface NftPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NftPostRequest
   */
  Contract?: string;
}
/**
 *
 * @export
 * @interface NftholdersPostRequest
 */
export interface NftholdersPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NftholdersPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof NftholdersPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NftholdersPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface NftholdersofuserPostRequest
 */
export interface NftholdersofuserPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NftholdersofuserPostRequest
   */
  Contract?: string;
  /**
   * ブロックチェーン上のアドレス
   * @type {string}
   * @memberof NftholdersofuserPostRequest
   */
  Address?: string;
  /**
   *
   * @type {number}
   * @memberof NftholdersofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NftholdersofuserPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface NfttokeninfoPostRequest
 */
export interface NfttokeninfoPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NfttokeninfoPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof NfttokeninfoPostRequest
   */
  Token?: string;
}
/**
 *
 * @export
 * @interface NfttokentransactionsPostRequest
 */
export interface NfttokentransactionsPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NfttokentransactionsPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof NfttokentransactionsPostRequest
   */
  Token?: string;
  /**
   *
   * @type {number}
   * @memberof NfttokentransactionsPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NfttokentransactionsPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface NfttransactionsofuserPostRequest
 */
export interface NfttransactionsofuserPostRequest {
  /**
   *
   * @type {string}
   * @memberof NfttransactionsofuserPostRequest
   */
  User?: string;
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof NfttransactionsofuserPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {number}
   * @memberof NfttransactionsofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof NfttransactionsofuserPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface PLTCreatedAddresses
 */
export interface PLTCreatedAddresses {
  /**
   *
   * @type {number}
   * @memberof PLTCreatedAddresses
   */
  Year?: number;
  /**
   *
   * @type {Array<CreatedAddressMonthInfo>}
   * @memberof PLTCreatedAddresses
   */
  MonthInfo?: Array<CreatedAddressMonthInfo>;
}
/**
 *
 * @export
 * @interface PLTHolder
 */
export interface PLTHolder {
  /**
   *
   * @type {string}
   * @memberof PLTHolder
   */
  Address?: string;
  /**
   *
   * @type {number}
   * @memberof PLTHolder
   */
  Amount?: number;
}
/**
 *
 * @export
 * @interface PRC20Holder
 */
export interface PRC20Holder {
  /**
   * ブロックチェーン上のアドレス
   * @type {string}
   * @memberof PRC20Holder
   */
  Address?: string;
  /**
   *
   * @type {number}
   * @memberof PRC20Holder
   */
  Amount?: number;
}
/**
 *
 * @export
 * @interface PltcreatedaddressesPostRequest
 */
export interface PltcreatedaddressesPostRequest {
  /**
   *
   * @type {number}
   * @memberof PltcreatedaddressesPostRequest
   */
  Contract?: number;
}
/**
 *
 * @export
 * @interface PltholderinfoPostRequest
 */
export interface PltholderinfoPostRequest {
  /**
   * ブロックチェーン上のアドレス
   * @type {string}
   * @memberof PltholderinfoPostRequest
   */
  Address?: string;
}
/**
 *
 * @export
 * @interface PlttransactionsPostRequest
 */
export interface PlttransactionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PlttransactionsPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof PlttransactionsPostRequest
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PlttransactionsPostRequest
   */
  BlockNumberStart?: number;
  /**
   *
   * @type {number}
   * @memberof PlttransactionsPostRequest
   */
  BlockNumberEnd?: number;
}
/**
 *
 * @export
 * @interface PlttransactionsofuserPostRequest
 */
export interface PlttransactionsofuserPostRequest {
  /**
   *
   * @type {number}
   * @memberof PlttransactionsofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof PlttransactionsofuserPostRequest
   */
  PageSize?: number;
  /**
   *
   * @type {string}
   * @memberof PlttransactionsofuserPostRequest
   */
  User?: string;
}
/**
 *
 * @export
 * @interface Prc20holderinfoPostRequest
 */
export interface Prc20holderinfoPostRequest {
  /**
   * ブロックチェーン上のアドレス
   * @type {string}
   * @memberof Prc20holderinfoPostRequest
   */
  Address?: string;
}
/**
 *
 * @export
 * @interface Prc20transactionsPostRequest
 */
export interface Prc20transactionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsPostRequest
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsPostRequest
   */
  BlockNumberStart?: number;
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsPostRequest
   */
  BlockNumberEnd?: number;
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof Prc20transactionsPostRequest
   */
  Contract?: string;
}
/**
 *
 * @export
 * @interface Prc20transactionsofuserPostRequest
 */
export interface Prc20transactionsofuserPostRequest {
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Prc20transactionsofuserPostRequest
   */
  PageSize?: number;
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof Prc20transactionsofuserPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof Prc20transactionsofuserPostRequest
   */
  User?: string;
}
/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  image?: string;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ProposeInfo
 */
export interface ProposeInfo {
  /**
   *
   * @type {string}
   * @memberof ProposeInfo
   */
  ProposeId?: string;
  /**
   *
   * @type {string}
   * @memberof ProposeInfo
   */
  Proposer?: string;
  /**
   *
   * @type {string}
   * @memberof ProposeInfo
   */
  Value?: string;
  /**
   *
   * @type {number}
   * @memberof ProposeInfo
   */
  EndBlock?: number;
  /**
   *
   * @type {number}
   * @memberof ProposeInfo
   */
  Type?: number;
  /**
   *
   * @type {number}
   * @memberof ProposeInfo
   */
  State?: number;
}
/**
 *
 * @export
 * @interface ProposeinfoPostRequest
 */
export interface ProposeinfoPostRequest {
  /**
   *
   * @type {string}
   * @memberof ProposeinfoPostRequest
   */
  ProposeId?: string;
}
/**
 *
 * @export
 * @interface Proposes
 */
export interface Proposes {
  /**
   *
   * @type {number}
   * @memberof Proposes
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Proposes
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Proposes
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Proposes
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<ProposeInfo>}
   * @memberof Proposes
   */
  ProposeInfos?: Array<ProposeInfo>;
}
/**
 *
 * @export
 * @interface ProposesofuserPostRequest
 */
export interface ProposesofuserPostRequest {
  /**
   *
   * @type {number}
   * @memberof ProposesofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof ProposesofuserPostRequest
   */
  PageSize?: number;
  /**
   *
   * @type {string}
   * @memberof ProposesofuserPostRequest
   */
  Proposer?: string;
}
/**
 *
 * @export
 * @interface Stake
 */
export interface Stake {
  /**
   *
   * @type {string}
   * @memberof Stake
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof Stake
   */
  Validator?: string;
  /**
   *
   * @type {string}
   * @memberof Stake
   */
  Amount?: string;
}
/**
 *
 * @export
 * @interface StakeinfoPostRequest
 */
export interface StakeinfoPostRequest {
  /**
   *
   * @type {string}
   * @memberof StakeinfoPostRequest
   */
  Owner?: string;
  /**
   *
   * @type {string}
   * @memberof StakeinfoPostRequest
   */
  Validator?: string;
}
/**
 *
 * @export
 * @interface Stakes
 */
export interface Stakes {
  /**
   *
   * @type {number}
   * @memberof Stakes
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Stakes
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Stakes
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Stakes
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<Stake>}
   * @memberof Stakes
   */
  StakeInfos?: Array<Stake>;
}
/**
 *
 * @export
 * @interface StakesofvalidatorPostRequest
 */
export interface StakesofvalidatorPostRequest {
  /**
   *
   * @type {string}
   * @memberof StakesofvalidatorPostRequest
   */
  Validator?: string;
  /**
   *
   * @type {number}
   * @memberof StakesofvalidatorPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof StakesofvalidatorPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  Hash?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  From?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  Cost?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  Data?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  Gas?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  GasPrice?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  To?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  Value?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  BlockNumber?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  Type?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  Status?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  BlockHash?: string;
}
/**
 *
 * @export
 * @interface Transaction1
 */
export interface Transaction1 {
  /**
   *
   * @type {string}
   * @memberof Transaction1
   */
  Hash?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction1
   */
  From?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Cost?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction1
   */
  Data?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Gas?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  GasPrice?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction1
   */
  To?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Value?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  BlockNumber?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Type?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction1
   */
  Status?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction1
   */
  BlockHash?: string;
  /**
   *
   * @type {Event}
   * @memberof Transaction1
   */
  Events?: Event;
  /**
   *
   * @type {TransactionDetail}
   * @memberof Transaction1
   */
  TransactionDetails?: TransactionDetail;
}
/**
 *
 * @export
 * @interface Transaction2
 */
export interface Transaction2 {
  /**
   *
   * @type {string}
   * @memberof Transaction2
   */
  Hash?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction2
   */
  From?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Cost?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction2
   */
  Data?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Gas?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  GasPrice?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction2
   */
  To?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Value?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  BlockNumber?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Type?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction2
   */
  Status?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction2
   */
  BlockHash?: string;
  /**
   *
   * @type {Event}
   * @memberof Transaction2
   */
  Events?: Event;
  /**
   *
   * @type {TransactionDetail2}
   * @memberof Transaction2
   */
  TransactionDetails?: TransactionDetail2;
}
/**
 *
 * @export
 * @interface TransactionDetail
 */
export interface TransactionDetail {
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  From?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  To?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  Value?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionDetail
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionDetail
   */
  Status?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  TransactionHash?: string;
}
/**
 *
 * @export
 * @interface TransactionDetail2
 */
export interface TransactionDetail2 {
  /**
   *
   * @type {string}
   * @memberof TransactionDetail2
   */
  Contract?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail2
   */
  From?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail2
   */
  To?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail2
   */
  Value?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionDetail2
   */
  Time?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionDetail2
   */
  Status?: number;
  /**
   *
   * @type {boolean}
   * @memberof TransactionDetail2
   */
  IsNft?: boolean;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail2
   */
  TransactionHash?: string;
}
/**
 *
 * @export
 * @interface TransactiondetailsPostRequest
 */
export interface TransactiondetailsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransactiondetailsPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof TransactiondetailsPostRequest
   */
  PageSize?: number;
  /**
   * トランザクションハッシュの値。0xから始まる
   * @type {string}
   * @memberof TransactiondetailsPostRequest
   */
  Hash?: string;
}
/**
 *
 * @export
 * @interface Transactions
 */
export interface Transactions {
  /**
   *
   * @type {number}
   * @memberof Transactions
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<TransactionDetail>}
   * @memberof Transactions
   */
  TransactionDetails?: Array<TransactionDetail>;
}
/**
 *
 * @export
 * @interface Transactions2
 */
export interface Transactions2 {
  /**
   *
   * @type {number}
   * @memberof Transactions2
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions2
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions2
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Transactions2
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<Transaction2>}
   * @memberof Transactions2
   */
  Transactions?: Array<Transaction2>;
}
/**
 *
 * @export
 * @interface TransactionsfromtouserPostRequest
 */
export interface TransactionsfromtouserPostRequest {
  /**
   *
   * @type {string}
   * @memberof TransactionsfromtouserPostRequest
   */
  User?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionsfromtouserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionsfromtouserPostRequest
   */
  PageSize?: number;
}
/**
 *
 * @export
 * @interface TransactionsofcontractPostRequest
 */
export interface TransactionsofcontractPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransactionsofcontractPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionsofcontractPostRequest
   */
  PageSize?: number;
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof TransactionsofcontractPostRequest
   */
  Contract?: string;
}
/**
 *
 * @export
 * @interface TransactionsofuserPostRequest
 */
export interface TransactionsofuserPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransactionsofuserPostRequest
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionsofuserPostRequest
   */
  PageSize?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionsofuserPostRequest
   */
  User?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionsofuserPostRequest
   */
  To?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionsofuserPostRequest
   */
  BlockNumberStart?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionsofuserPostRequest
   */
  BlockNumberEnd?: number;
}
/**
 *
 * @export
 * @interface Validator1
 */
export interface Validator1 {
  /**
   *
   * @type {string}
   * @memberof Validator1
   */
  Address?: string;
  /**
   *
   * @type {number}
   * @memberof Validator1
   */
  DelegateFactor?: number;
  /**
   *
   * @type {number}
   * @memberof Validator1
   */
  StakeAmount?: number;
  /**
   *
   * @type {string}
   * @memberof Validator1
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof Validator1
   */
  Uri?: string;
}
/**
 *
 * @export
 * @interface Validators
 */
export interface Validators {
  /**
   *
   * @type {number}
   * @memberof Validators
   */
  PageSize?: number;
  /**
   *
   * @type {number}
   * @memberof Validators
   */
  PageNo?: number;
  /**
   *
   * @type {number}
   * @memberof Validators
   */
  TotalPage?: number;
  /**
   *
   * @type {number}
   * @memberof Validators
   */
  TotalCount?: number;
  /**
   *
   * @type {Array<Validator1>}
   * @memberof Validators
   */
  ValidatorInfos?: Array<Validator1>;
}
/**
 *
 * @export
 * @interface Verification
 */
export interface Verification {
  /**
   *
   * @type {string}
   * @memberof Verification
   */
  Id?: string;
}
/**
 *
 * @export
 * @interface VerifycontractPostRequest
 */
export interface VerifycontractPostRequest {
  /**
   * ブロックチェーン上のContract Address
   * @type {string}
   * @memberof VerifycontractPostRequest
   */
  Contract?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VerifycontractPostRequest
   */
  Codes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VerifycontractPostRequest
   */
  ContractName?: string;
  /**
   * solidityのバージョン
   * @type {string}
   * @memberof VerifycontractPostRequest
   */
  CompilerVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof VerifycontractPostRequest
   */
  OptimizationUsed?: boolean;
  /**
   *
   * @type {number}
   * @memberof VerifycontractPostRequest
   */
  Runs?: number;
  /**
   *
   * @type {string}
   * @memberof VerifycontractPostRequest
   */
  EvmVersion?: string;
  /**
   *
   * @type {Array<Library>}
   * @memberof VerifycontractPostRequest
   */
  Libraries?: Array<Library>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allerc1155sholdersofuserPost: async (
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/allerc1155sholdersofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        allnftsholdersofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary allerc1155sofuser
     * @param {Allerc1155sofuserPostRequest} [allerc1155sofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allerc1155sofuserPost: async (
      allerc1155sofuserPostRequest?: Allerc1155sofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/allerc1155sofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        allerc1155sofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allnftsholdersofuserPost: async (
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/allnftsholdersofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        allnftsholdersofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary allnftsofuser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allnftsofuserPost: async (
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/allnftsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionsfromtouserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Find target block info by hash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbyhashPost: async (
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/blockbyhash`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbyhashPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Find target block info by number
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbynumberPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/blockbynumber`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Find target block info by timestamp
     * @param {BlockbytimestampPostRequest} [blockbytimestampPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbytimestampPost: async (
      blockbytimestampPostRequest?: BlockbytimestampPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/blockbytimestamp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbytimestampPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Blocks
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blocksPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/blocks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Chain Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chainGet: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/chain`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Contract Verification
     * @param {Verification} [verification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractverificationPost: async (
      verification?: Verification,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contractverification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verification,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155Post: async (
      nftPostRequest?: NftPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155holders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155holdersPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155holders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155holdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155holdersofuserPost: async (
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155holdersofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155s
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155sPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155s`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155tokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155tokeninfoPost: async (
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155tokeninfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttokeninfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155tokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155tokentransactionsPost: async (
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155tokentransactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttokentransactionsPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155transactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155transactionsPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155transactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155transactionsofuserPost: async (
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155transactionsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttransactionsofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary erc1155users
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155usersPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/erc1155users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Events
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/events`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Find events by contract address
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsofcontractPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/eventsofcontract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nft
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftPost: async (
      nftPostRequest?: NftPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nft`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nftholders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftholdersPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nftholders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nftholdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftholdersofuserPost: async (
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nftholdersofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nfts
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftsPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nfts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nfttokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttokeninfoPost: async (
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nfttokeninfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttokeninfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nfttokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttokentransactionsPost: async (
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nfttokentransactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttokentransactionsPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nfttransactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttransactionsPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nfttransactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nfttransactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttransactionsofuserPost: async (
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nfttransactionsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nfttransactionsofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary nftusers
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftusersPost: async (
      nftholdersPostRequest?: NftholdersPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/nftusers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nftholdersPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary pltcreatedaddresses
     * @param {PltcreatedaddressesPostRequest} [pltcreatedaddressesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pltcreatedaddressesPost: async (
      pltcreatedaddressesPostRequest?: PltcreatedaddressesPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/pltcreatedaddresses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pltcreatedaddressesPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary pltholderinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pltholderinfoPost: async (
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/pltholderinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pltholderinfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary plttransactions
     * @param {PlttransactionsPostRequest} [plttransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plttransactionsPost: async (
      plttransactionsPostRequest?: PlttransactionsPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/plttransactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        plttransactionsPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary plttransactionsofuser
     * @param {PlttransactionsofuserPostRequest} [plttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plttransactionsofuserPost: async (
      plttransactionsofuserPostRequest?: PlttransactionsofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/plttransactionsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        plttransactionsofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary prc20 holder info
     * @param {Prc20holderinfoPostRequest} [prc20holderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20holderinfoPost: async (
      prc20holderinfoPostRequest?: Prc20holderinfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/prc20holderinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        prc20holderinfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary prc20 transactions
     * @param {Prc20transactionsPostRequest} [prc20transactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20transactionsPost: async (
      prc20transactionsPostRequest?: Prc20transactionsPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/prc20transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        prc20transactionsPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary prc20 transactions of user
     * @param {Prc20transactionsofuserPostRequest} [prc20transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20transactionsofuserPost: async (
      prc20transactionsofuserPostRequest?: Prc20transactionsofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/prc20transactionsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        prc20transactionsofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary proposeinfo
     * @param {ProposeinfoPostRequest} [proposeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposeinfoPost: async (
      proposeinfoPostRequest?: ProposeinfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/proposeinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        proposeinfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary proposes
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposesPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/proposes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary proposesofuser
     * @param {ProposesofuserPostRequest} [proposesofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposesofuserPost: async (
      proposesofuserPostRequest?: ProposesofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/proposesofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        proposesofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootGet: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary stakeinfo
     * @param {StakeinfoPostRequest} [stakeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakeinfoPost: async (
      stakeinfoPostRequest?: StakeinfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stakeinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stakeinfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary stakesofowner
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakesofownerPost: async (
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stakesofowner`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        allnftsholdersofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary stakesofvalidator
     * @param {StakesofvalidatorPostRequest} [stakesofvalidatorPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakesofvalidatorPost: async (
      stakesofvalidatorPostRequest?: StakesofvalidatorPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stakesofvalidator`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stakesofvalidatorPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactionbyhash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionbyhashPost: async (
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactionbyhash`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbyhashPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactiondetails
     * @param {TransactiondetailsPostRequest} [transactiondetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactiondetailsPost: async (
      transactiondetailsPostRequest?: TransactiondetailsPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactiondetails`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactiondetailsPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactiondetailsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactiondetailsfromtouserPost: async (
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactiondetailsfromtouser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionsfromtouserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactionsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsfromtouserPost: async (
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactionsfromtouser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionsfromtouserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactionsofcontract
     * @param {TransactionsofcontractPostRequest} [transactionsofcontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsofcontractPost: async (
      transactionsofcontractPostRequest?: TransactionsofcontractPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactionsofcontract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionsofcontractPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary transactionsofuser
     * @param {TransactionsofuserPostRequest} [transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsofuserPost: async (
      transactionsofuserPostRequest?: TransactionsofuserPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transactionsofuser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionsofuserPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary validatorinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatorinfoPost: async (
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/validatorinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pltholderinfoPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary validators
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatorsPost: async (
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/validators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockbynumberPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary contract verification request
     * @param {VerifycontractPostRequest} [verifycontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifycontractPost: async (
      verifycontractPostRequest?: VerifycontractPostRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/verifycontract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifycontractPostRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allerc1155sholdersofuserPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFTUsers>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.allerc1155sholdersofuserPost(
          allnftsholdersofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.allerc1155sholdersofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary allerc1155sofuser
     * @param {Allerc1155sofuserPostRequest} [allerc1155sofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allerc1155sofuserPost(
      allerc1155sofuserPostRequest?: Allerc1155sofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ERC1155Users>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.allerc1155sofuserPost(
          allerc1155sofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.allerc1155sofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allnftsholdersofuserPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFTUsers>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.allnftsholdersofuserPost(
          allnftsholdersofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.allnftsholdersofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary allnftsofuser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allnftsofuserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllNFTsOfUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.allnftsofuserPost(
          transactionsfromtouserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.allnftsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Find target block info by hash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockbyhashPost(
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blockbyhashPost(
        blockbyhashPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.blockbyhashPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Find target block info by number
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockbynumberPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockbynumberPost(
          blockbynumberPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.blockbynumberPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Find target block info by timestamp
     * @param {BlockbytimestampPostRequest} [blockbytimestampPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockbytimestampPost(
      blockbytimestampPostRequest?: BlockbytimestampPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockbytimestampPost(
          blockbytimestampPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.blockbytimestampPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Blocks
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blocksPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Blocks>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blocksPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.blocksPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Chain Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chainGet(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chain>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chainGet(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.chainGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Contract Verification
     * @param {Verification} [verification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractverificationPost(
      verification?: Verification,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContractVerification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contractverificationPost(
          verification,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.contractverificationPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155Post(
      nftPostRequest?: NftPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFT>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.erc1155Post(
        nftPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155Post"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155holders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155holdersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155holdersPost(
          nftholdersPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155holdersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155holdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155holdersofuserPost(
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ERC1155Tokensofuser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155holdersofuserPost(
          nftholdersofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155holdersofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155s
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155sPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.erc1155sPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155sPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155tokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155tokeninfoPost(
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ERC1155Token>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155tokeninfoPost(
          nfttokeninfoPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155tokeninfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155tokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155tokentransactionsPost(
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ERC1155TokenTransactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155tokentransactionsPost(
          nfttokentransactionsPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155tokentransactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155transactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155transactionsPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155transactionsPost(
          nftholdersPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155transactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155transactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155transactionsofuserPost(
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155transactionsofuserPost(
          nfttransactionsofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155transactionsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary erc1155users
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async erc1155usersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ERC1155Users>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.erc1155usersPost(
          nftholdersPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.erc1155usersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Events
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Events>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventsPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.eventsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Find events by contract address
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsofcontractPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Events>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.eventsofcontractPost(
          nftholdersPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.eventsofcontractPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nft
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nftPost(
      nftPostRequest?: NftPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFT>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nftPost(
        nftPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nftPost"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nftholders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nftholdersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nftholdersPost(
        nftholdersPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nftholdersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nftholdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nftholdersofuserPost(
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFTTokens>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nftholdersofuserPost(
          nftholdersofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nftholdersofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nfts
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nftsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nftsPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nftsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nfttokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nfttokeninfoPost(
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFTToken>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nfttokeninfoPost(
          nfttokeninfoPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nfttokeninfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nfttokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nfttokentransactionsPost(
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NFTTokenTransactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nfttokentransactionsPost(
          nfttokentransactionsPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nfttokentransactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nfttransactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nfttransactionsPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nfttransactionsPost(
          nftholdersPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nfttransactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nfttransactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nfttransactionsofuserPost(
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nfttransactionsofuserPost(
          nfttransactionsofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nfttransactionsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary nftusers
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nftusersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFTUsers>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nftusersPost(
        nftholdersPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.nftusersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary pltcreatedaddresses
     * @param {PltcreatedaddressesPostRequest} [pltcreatedaddressesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pltcreatedaddressesPost(
      pltcreatedaddressesPostRequest?: PltcreatedaddressesPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PLTCreatedAddresses>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.pltcreatedaddressesPost(
          pltcreatedaddressesPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.pltcreatedaddressesPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary pltholderinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pltholderinfoPost(
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PLTHolder>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.pltholderinfoPost(
          pltholderinfoPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.pltholderinfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary plttransactions
     * @param {PlttransactionsPostRequest} [plttransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plttransactionsPost(
      plttransactionsPostRequest?: PlttransactionsPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plttransactionsPost(
          plttransactionsPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.plttransactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary plttransactionsofuser
     * @param {PlttransactionsofuserPostRequest} [plttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async plttransactionsofuserPost(
      plttransactionsofuserPostRequest?: PlttransactionsofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.plttransactionsofuserPost(
          plttransactionsofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.plttransactionsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary prc20 holder info
     * @param {Prc20holderinfoPostRequest} [prc20holderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prc20holderinfoPost(
      prc20holderinfoPostRequest?: Prc20holderinfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PRC20Holder>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.prc20holderinfoPost(
          prc20holderinfoPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.prc20holderinfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary prc20 transactions
     * @param {Prc20transactionsPostRequest} [prc20transactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prc20transactionsPost(
      prc20transactionsPostRequest?: Prc20transactionsPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.prc20transactionsPost(
          prc20transactionsPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.prc20transactionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary prc20 transactions of user
     * @param {Prc20transactionsofuserPostRequest} [prc20transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prc20transactionsofuserPost(
      prc20transactionsofuserPostRequest?: Prc20transactionsofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.prc20transactionsofuserPost(
          prc20transactionsofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.prc20transactionsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary proposeinfo
     * @param {ProposeinfoPostRequest} [proposeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposeinfoPost(
      proposeinfoPostRequest?: ProposeinfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ProposeInfo>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proposeinfoPost(
        proposeinfoPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.proposeinfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary proposes
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposesPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proposes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proposesPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.proposesPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary proposesofuser
     * @param {ProposesofuserPostRequest} [proposesofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proposesofuserPost(
      proposesofuserPostRequest?: ProposesofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.proposesofuserPost(
          proposesofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.proposesofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootGet(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.rootGet"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary stakeinfo
     * @param {StakeinfoPostRequest} [stakeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stakeinfoPost(
      stakeinfoPostRequest?: StakeinfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stake>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stakeinfoPost(
        stakeinfoPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.stakeinfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary stakesofowner
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stakesofownerPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stakes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stakesofownerPost(
          allnftsholdersofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.stakesofownerPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary stakesofvalidator
     * @param {StakesofvalidatorPostRequest} [stakesofvalidatorPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stakesofvalidatorPost(
      stakesofvalidatorPostRequest?: StakesofvalidatorPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stakes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stakesofvalidatorPost(
          stakesofvalidatorPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.stakesofvalidatorPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactionbyhash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactionbyhashPost(
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction1>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactionbyhashPost(
          blockbyhashPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactionbyhashPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactiondetails
     * @param {TransactiondetailsPostRequest} [transactiondetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactiondetailsPost(
      transactiondetailsPostRequest?: TransactiondetailsPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TransactionDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactiondetailsPost(
          transactiondetailsPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactiondetailsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactiondetailsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactiondetailsfromtouserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TransactionDetail2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactiondetailsfromtouserPost(
          transactionsfromtouserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactiondetailsfromtouserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactionsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactionsfromtouserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactionsfromtouserPost(
          transactionsfromtouserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactionsfromtouserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactionsofcontract
     * @param {TransactionsofcontractPostRequest} [transactionsofcontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactionsofcontractPost(
      transactionsofcontractPostRequest?: TransactionsofcontractPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactionsofcontractPost(
          transactionsofcontractPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactionsofcontractPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary transactionsofuser
     * @param {TransactionsofuserPostRequest} [transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactionsofuserPost(
      transactionsofuserPostRequest?: TransactionsofuserPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transactions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transactionsofuserPost(
          transactionsofuserPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.transactionsofuserPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary validatorinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validatorinfoPost(
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Validator1>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validatorinfoPost(
          pltholderinfoPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.validatorinfoPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary validators
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validatorsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Validators>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validatorsPost(
        blockbynumberPostRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.validatorsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary contract verification request
     * @param {VerifycontractPostRequest} [verifycontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifycontractPost(
      verifycontractPostRequest?: VerifycontractPostRequest,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Verification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifycontractPost(
          verifycontractPostRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DefaultApi.verifycontractPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allerc1155sholdersofuserPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: any
    ): AxiosPromise<NFTUsers> {
      return localVarFp
        .allerc1155sholdersofuserPost(allnftsholdersofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary allerc1155sofuser
     * @param {Allerc1155sofuserPostRequest} [allerc1155sofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allerc1155sofuserPost(
      allerc1155sofuserPostRequest?: Allerc1155sofuserPostRequest,
      options?: any
    ): AxiosPromise<ERC1155Users> {
      return localVarFp
        .allerc1155sofuserPost(allerc1155sofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary allnftsholdersofuser
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allnftsholdersofuserPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: any
    ): AxiosPromise<NFTUsers> {
      return localVarFp
        .allnftsholdersofuserPost(allnftsholdersofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary allnftsofuser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allnftsofuserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: any
    ): AxiosPromise<AllNFTsOfUser> {
      return localVarFp
        .allnftsofuserPost(transactionsfromtouserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Find target block info by hash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbyhashPost(
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options?: any
    ): AxiosPromise<Block> {
      return localVarFp
        .blockbyhashPost(blockbyhashPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Find target block info by number
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbynumberPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<Block> {
      return localVarFp
        .blockbynumberPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Find target block info by timestamp
     * @param {BlockbytimestampPostRequest} [blockbytimestampPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockbytimestampPost(
      blockbytimestampPostRequest?: BlockbytimestampPostRequest,
      options?: any
    ): AxiosPromise<Block> {
      return localVarFp
        .blockbytimestampPost(blockbytimestampPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Blocks
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blocksPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<Blocks> {
      return localVarFp
        .blocksPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Chain Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chainGet(options?: any): AxiosPromise<Chain> {
      return localVarFp
        .chainGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Contract Verification
     * @param {Verification} [verification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractverificationPost(
      verification?: Verification,
      options?: any
    ): AxiosPromise<ContractVerification> {
      return localVarFp
        .contractverificationPost(verification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155Post(
      nftPostRequest?: NftPostRequest,
      options?: any
    ): AxiosPromise<NFT> {
      return localVarFp
        .erc1155Post(nftPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155holders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155holdersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .erc1155holdersPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155holdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155holdersofuserPost(
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options?: any
    ): AxiosPromise<ERC1155Tokensofuser> {
      return localVarFp
        .erc1155holdersofuserPost(nftholdersofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155s
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155sPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .erc1155sPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155tokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155tokeninfoPost(
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options?: any
    ): AxiosPromise<ERC1155Token> {
      return localVarFp
        .erc1155tokeninfoPost(nfttokeninfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155tokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155tokentransactionsPost(
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options?: any
    ): AxiosPromise<ERC1155TokenTransactions> {
      return localVarFp
        .erc1155tokentransactionsPost(nfttokentransactionsPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155transactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155transactionsPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .erc1155transactionsPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155transactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155transactionsofuserPost(
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .erc1155transactionsofuserPost(
          nfttransactionsofuserPostRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary erc1155users
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    erc1155usersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<ERC1155Users> {
      return localVarFp
        .erc1155usersPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Events
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<Events> {
      return localVarFp
        .eventsPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Find events by contract address
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsofcontractPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<Events> {
      return localVarFp
        .eventsofcontractPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nft
     * @param {NftPostRequest} [nftPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftPost(nftPostRequest?: NftPostRequest, options?: any): AxiosPromise<NFT> {
      return localVarFp
        .nftPost(nftPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nftholders
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftholdersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .nftholdersPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nftholdersofuser
     * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftholdersofuserPost(
      nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
      options?: any
    ): AxiosPromise<NFTTokens> {
      return localVarFp
        .nftholdersofuserPost(nftholdersofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nfts
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .nftsPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nfttokeninfo
     * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttokeninfoPost(
      nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
      options?: any
    ): AxiosPromise<NFTToken> {
      return localVarFp
        .nfttokeninfoPost(nfttokeninfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nfttokentransactions
     * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttokentransactionsPost(
      nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
      options?: any
    ): AxiosPromise<NFTTokenTransactions> {
      return localVarFp
        .nfttokentransactionsPost(nfttokentransactionsPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nfttransactions
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttransactionsPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .nfttransactionsPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nfttransactionsofuser
     * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nfttransactionsofuserPost(
      nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .nfttransactionsofuserPost(nfttransactionsofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary nftusers
     * @param {NftholdersPostRequest} [nftholdersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nftusersPost(
      nftholdersPostRequest?: NftholdersPostRequest,
      options?: any
    ): AxiosPromise<NFTUsers> {
      return localVarFp
        .nftusersPost(nftholdersPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary pltcreatedaddresses
     * @param {PltcreatedaddressesPostRequest} [pltcreatedaddressesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pltcreatedaddressesPost(
      pltcreatedaddressesPostRequest?: PltcreatedaddressesPostRequest,
      options?: any
    ): AxiosPromise<PLTCreatedAddresses> {
      return localVarFp
        .pltcreatedaddressesPost(pltcreatedaddressesPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary pltholderinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pltholderinfoPost(
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options?: any
    ): AxiosPromise<PLTHolder> {
      return localVarFp
        .pltholderinfoPost(pltholderinfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary plttransactions
     * @param {PlttransactionsPostRequest} [plttransactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plttransactionsPost(
      plttransactionsPostRequest?: PlttransactionsPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .plttransactionsPost(plttransactionsPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary plttransactionsofuser
     * @param {PlttransactionsofuserPostRequest} [plttransactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    plttransactionsofuserPost(
      plttransactionsofuserPostRequest?: PlttransactionsofuserPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .plttransactionsofuserPost(plttransactionsofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary prc20 holder info
     * @param {Prc20holderinfoPostRequest} [prc20holderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20holderinfoPost(
      prc20holderinfoPostRequest?: Prc20holderinfoPostRequest,
      options?: any
    ): AxiosPromise<PRC20Holder> {
      return localVarFp
        .prc20holderinfoPost(prc20holderinfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary prc20 transactions
     * @param {Prc20transactionsPostRequest} [prc20transactionsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20transactionsPost(
      prc20transactionsPostRequest?: Prc20transactionsPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .prc20transactionsPost(prc20transactionsPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary prc20 transactions of user
     * @param {Prc20transactionsofuserPostRequest} [prc20transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prc20transactionsofuserPost(
      prc20transactionsofuserPostRequest?: Prc20transactionsofuserPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .prc20transactionsofuserPost(
          prc20transactionsofuserPostRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary proposeinfo
     * @param {ProposeinfoPostRequest} [proposeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposeinfoPost(
      proposeinfoPostRequest?: ProposeinfoPostRequest,
      options?: any
    ): AxiosPromise<Array<ProposeInfo>> {
      return localVarFp
        .proposeinfoPost(proposeinfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary proposes
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposesPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<Proposes> {
      return localVarFp
        .proposesPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary proposesofuser
     * @param {ProposesofuserPostRequest} [proposesofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proposesofuserPost(
      proposesofuserPostRequest?: ProposesofuserPostRequest,
      options?: any
    ): AxiosPromise<Transaction> {
      return localVarFp
        .proposesofuserPost(proposesofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootGet(options?: any): AxiosPromise<Info> {
      return localVarFp
        .rootGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary stakeinfo
     * @param {StakeinfoPostRequest} [stakeinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakeinfoPost(
      stakeinfoPostRequest?: StakeinfoPostRequest,
      options?: any
    ): AxiosPromise<Stake> {
      return localVarFp
        .stakeinfoPost(stakeinfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary stakesofowner
     * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakesofownerPost(
      allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
      options?: any
    ): AxiosPromise<Stakes> {
      return localVarFp
        .stakesofownerPost(allnftsholdersofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary stakesofvalidator
     * @param {StakesofvalidatorPostRequest} [stakesofvalidatorPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stakesofvalidatorPost(
      stakesofvalidatorPostRequest?: StakesofvalidatorPostRequest,
      options?: any
    ): AxiosPromise<Stakes> {
      return localVarFp
        .stakesofvalidatorPost(stakesofvalidatorPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactionbyhash
     * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionbyhashPost(
      blockbyhashPostRequest?: BlockbyhashPostRequest,
      options?: any
    ): AxiosPromise<Transaction1> {
      return localVarFp
        .transactionbyhashPost(blockbyhashPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactiondetails
     * @param {TransactiondetailsPostRequest} [transactiondetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactiondetailsPost(
      transactiondetailsPostRequest?: TransactiondetailsPostRequest,
      options?: any
    ): AxiosPromise<TransactionDetail> {
      return localVarFp
        .transactiondetailsPost(transactiondetailsPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactiondetailsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactiondetailsfromtouserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: any
    ): AxiosPromise<TransactionDetail2> {
      return localVarFp
        .transactiondetailsfromtouserPost(
          transactionsfromtouserPostRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactionsfromtouser
     * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsfromtouserPost(
      transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
      options?: any
    ): AxiosPromise<Transactions2> {
      return localVarFp
        .transactionsfromtouserPost(transactionsfromtouserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactionsofcontract
     * @param {TransactionsofcontractPostRequest} [transactionsofcontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsofcontractPost(
      transactionsofcontractPostRequest?: TransactionsofcontractPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .transactionsofcontractPost(transactionsofcontractPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary transactionsofuser
     * @param {TransactionsofuserPostRequest} [transactionsofuserPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionsofuserPost(
      transactionsofuserPostRequest?: TransactionsofuserPostRequest,
      options?: any
    ): AxiosPromise<Transactions> {
      return localVarFp
        .transactionsofuserPost(transactionsofuserPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary validatorinfo
     * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatorinfoPost(
      pltholderinfoPostRequest?: PltholderinfoPostRequest,
      options?: any
    ): AxiosPromise<Validator1> {
      return localVarFp
        .validatorinfoPost(pltholderinfoPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary validators
     * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatorsPost(
      blockbynumberPostRequest?: BlockbynumberPostRequest,
      options?: any
    ): AxiosPromise<Validators> {
      return localVarFp
        .validatorsPost(blockbynumberPostRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary contract verification request
     * @param {VerifycontractPostRequest} [verifycontractPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifycontractPost(
      verifycontractPostRequest?: VerifycontractPostRequest,
      options?: any
    ): AxiosPromise<Verification> {
      return localVarFp
        .verifycontractPost(verifycontractPostRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary allnftsholdersofuser
   * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public allerc1155sholdersofuserPost(
    allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .allerc1155sholdersofuserPost(allnftsholdersofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary allerc1155sofuser
   * @param {Allerc1155sofuserPostRequest} [allerc1155sofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public allerc1155sofuserPost(
    allerc1155sofuserPostRequest?: Allerc1155sofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .allerc1155sofuserPost(allerc1155sofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary allnftsholdersofuser
   * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public allnftsholdersofuserPost(
    allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .allnftsholdersofuserPost(allnftsholdersofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary allnftsofuser
   * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public allnftsofuserPost(
    transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .allnftsofuserPost(transactionsfromtouserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Find target block info by hash
   * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public blockbyhashPost(
    blockbyhashPostRequest?: BlockbyhashPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .blockbyhashPost(blockbyhashPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Find target block info by number
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public blockbynumberPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .blockbynumberPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Find target block info by timestamp
   * @param {BlockbytimestampPostRequest} [blockbytimestampPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public blockbytimestampPost(
    blockbytimestampPostRequest?: BlockbytimestampPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .blockbytimestampPost(blockbytimestampPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Blocks
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public blocksPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .blocksPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Chain Info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public chainGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .chainGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Contract Verification
   * @param {Verification} [verification]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public contractverificationPost(
    verification?: Verification,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .contractverificationPost(verification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155
   * @param {NftPostRequest} [nftPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155Post(
    nftPostRequest?: NftPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155Post(nftPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155holders
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155holdersPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155holdersPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155holdersofuser
   * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155holdersofuserPost(
    nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155holdersofuserPost(nftholdersofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155s
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155sPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155sPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155tokeninfo
   * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155tokeninfoPost(
    nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155tokeninfoPost(nfttokeninfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155tokentransactions
   * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155tokentransactionsPost(
    nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155tokentransactionsPost(nfttokentransactionsPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155transactions
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155transactionsPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155transactionsPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155transactionsofuser
   * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155transactionsofuserPost(
    nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155transactionsofuserPost(nfttransactionsofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary erc1155users
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public erc1155usersPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .erc1155usersPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Events
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public eventsPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .eventsPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Find events by contract address
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public eventsofcontractPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .eventsofcontractPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nft
   * @param {NftPostRequest} [nftPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nftPost(
    nftPostRequest?: NftPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nftPost(nftPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nftholders
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nftholdersPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nftholdersPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nftholdersofuser
   * @param {NftholdersofuserPostRequest} [nftholdersofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nftholdersofuserPost(
    nftholdersofuserPostRequest?: NftholdersofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nftholdersofuserPost(nftholdersofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nfts
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nftsPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nftsPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nfttokeninfo
   * @param {NfttokeninfoPostRequest} [nfttokeninfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nfttokeninfoPost(
    nfttokeninfoPostRequest?: NfttokeninfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nfttokeninfoPost(nfttokeninfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nfttokentransactions
   * @param {NfttokentransactionsPostRequest} [nfttokentransactionsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nfttokentransactionsPost(
    nfttokentransactionsPostRequest?: NfttokentransactionsPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nfttokentransactionsPost(nfttokentransactionsPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nfttransactions
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nfttransactionsPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nfttransactionsPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nfttransactionsofuser
   * @param {NfttransactionsofuserPostRequest} [nfttransactionsofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nfttransactionsofuserPost(
    nfttransactionsofuserPostRequest?: NfttransactionsofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nfttransactionsofuserPost(nfttransactionsofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary nftusers
   * @param {NftholdersPostRequest} [nftholdersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nftusersPost(
    nftholdersPostRequest?: NftholdersPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .nftusersPost(nftholdersPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary pltcreatedaddresses
   * @param {PltcreatedaddressesPostRequest} [pltcreatedaddressesPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public pltcreatedaddressesPost(
    pltcreatedaddressesPostRequest?: PltcreatedaddressesPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .pltcreatedaddressesPost(pltcreatedaddressesPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary pltholderinfo
   * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public pltholderinfoPost(
    pltholderinfoPostRequest?: PltholderinfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .pltholderinfoPost(pltholderinfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary plttransactions
   * @param {PlttransactionsPostRequest} [plttransactionsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public plttransactionsPost(
    plttransactionsPostRequest?: PlttransactionsPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .plttransactionsPost(plttransactionsPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary plttransactionsofuser
   * @param {PlttransactionsofuserPostRequest} [plttransactionsofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public plttransactionsofuserPost(
    plttransactionsofuserPostRequest?: PlttransactionsofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .plttransactionsofuserPost(plttransactionsofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary prc20 holder info
   * @param {Prc20holderinfoPostRequest} [prc20holderinfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public prc20holderinfoPost(
    prc20holderinfoPostRequest?: Prc20holderinfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .prc20holderinfoPost(prc20holderinfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary prc20 transactions
   * @param {Prc20transactionsPostRequest} [prc20transactionsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public prc20transactionsPost(
    prc20transactionsPostRequest?: Prc20transactionsPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .prc20transactionsPost(prc20transactionsPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary prc20 transactions of user
   * @param {Prc20transactionsofuserPostRequest} [prc20transactionsofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public prc20transactionsofuserPost(
    prc20transactionsofuserPostRequest?: Prc20transactionsofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .prc20transactionsofuserPost(prc20transactionsofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary proposeinfo
   * @param {ProposeinfoPostRequest} [proposeinfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public proposeinfoPost(
    proposeinfoPostRequest?: ProposeinfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .proposeinfoPost(proposeinfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary proposes
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public proposesPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .proposesPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary proposesofuser
   * @param {ProposesofuserPostRequest} [proposesofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public proposesofuserPost(
    proposesofuserPostRequest?: ProposesofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .proposesofuserPost(proposesofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rootGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rootGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary stakeinfo
   * @param {StakeinfoPostRequest} [stakeinfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public stakeinfoPost(
    stakeinfoPostRequest?: StakeinfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .stakeinfoPost(stakeinfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary stakesofowner
   * @param {AllnftsholdersofuserPostRequest} [allnftsholdersofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public stakesofownerPost(
    allnftsholdersofuserPostRequest?: AllnftsholdersofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .stakesofownerPost(allnftsholdersofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary stakesofvalidator
   * @param {StakesofvalidatorPostRequest} [stakesofvalidatorPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public stakesofvalidatorPost(
    stakesofvalidatorPostRequest?: StakesofvalidatorPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .stakesofvalidatorPost(stakesofvalidatorPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactionbyhash
   * @param {BlockbyhashPostRequest} [blockbyhashPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactionbyhashPost(
    blockbyhashPostRequest?: BlockbyhashPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactionbyhashPost(blockbyhashPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactiondetails
   * @param {TransactiondetailsPostRequest} [transactiondetailsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactiondetailsPost(
    transactiondetailsPostRequest?: TransactiondetailsPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactiondetailsPost(transactiondetailsPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactiondetailsfromtouser
   * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactiondetailsfromtouserPost(
    transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactiondetailsfromtouserPost(
        transactionsfromtouserPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactionsfromtouser
   * @param {TransactionsfromtouserPostRequest} [transactionsfromtouserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactionsfromtouserPost(
    transactionsfromtouserPostRequest?: TransactionsfromtouserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactionsfromtouserPost(transactionsfromtouserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactionsofcontract
   * @param {TransactionsofcontractPostRequest} [transactionsofcontractPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactionsofcontractPost(
    transactionsofcontractPostRequest?: TransactionsofcontractPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactionsofcontractPost(transactionsofcontractPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary transactionsofuser
   * @param {TransactionsofuserPostRequest} [transactionsofuserPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transactionsofuserPost(
    transactionsofuserPostRequest?: TransactionsofuserPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .transactionsofuserPost(transactionsofuserPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary validatorinfo
   * @param {PltholderinfoPostRequest} [pltholderinfoPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public validatorinfoPost(
    pltholderinfoPostRequest?: PltholderinfoPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .validatorinfoPost(pltholderinfoPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary validators
   * @param {BlockbynumberPostRequest} [blockbynumberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public validatorsPost(
    blockbynumberPostRequest?: BlockbynumberPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .validatorsPost(blockbynumberPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary contract verification request
   * @param {VerifycontractPostRequest} [verifycontractPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public verifycontractPost(
    verifycontractPostRequest?: VerifycontractPostRequest,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .verifycontractPost(verifycontractPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
