export enum Status {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  Other = "other",
}

export type Alert = {
  title: string;
  status: Status;
  cta: boolean;
  content: string;
  id?: number; // if it is popup
  autoRemove?: boolean;
  closeable?: boolean;
};

export type PopupAlert = Alert & {
  id: number;
};
