import type { InternalAxiosRequestConfig } from "axios";
import globalAxios from "axios";

const attachWithCredentialsTrue = (config: InternalAxiosRequestConfig) => {
  if (config.url && config.url.indexOf(import.meta.env.VITE_API_URL) >= 0) {
    config.withCredentials = true;
  }
  return config;
};
globalAxios.interceptors.request.use(attachWithCredentialsTrue);
