import "swiper/css";
import "swiper/css/pagination";
import "./MyMembershipSwiper.scss";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useRef, useState, useEffect } from "react";
import Prev from "../../assets/prev.svg?react";
import Next from "../../assets/next.svg?react";

export type MyMembershipSwiperProps = {
  imageUrls: string[];
  handleSlideChange: (index: number) => void;
};

export function NoImage() {
  return (
    <div className="no-image">
      <span>
        MEMBERSHIPの無い CHECK-INを
        <br /> 全て表示
      </span>
    </div>
  );
}

export default function MyMembershipSwiper({
  imageUrls,
  handleSlideChange,
}: MyMembershipSwiperProps) {
  const swiperRef = useRef<SwiperRef>(null);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isFirstSlide, setIsFirstSlide] = useState(true);

  const goPrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const goNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const _handleSlideChange = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      handleSlideChange(swiper.realIndex); // 現在のインデックスを更新
      setIsLastSlide(swiper.realIndex === imageUrls.length - 1);
      setIsFirstSlide(swiper.realIndex === 0);
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setIsLastSlide(swiper.realIndex === imageUrls.length - 1);
      setIsFirstSlide(swiper.realIndex === 0);
    }
  }, [imageUrls.length]);

  return (
    <div className="mymembership-swiper">
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        loop={true}
        ref={swiperRef}
        onSlideChange={_handleSlideChange}
      >
        {imageUrls.map((imageUrl, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-img">
              {imageUrl ? <img src={imageUrl} /> : <NoImage />}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {!isFirstSlide && <Prev onClick={() => goPrev()} className="prev" />}
      {!isLastSlide && <Next onClick={() => goNext()} className="next" />}
    </div>
  );
}
