export type NavType = Record<KeyType, { text: string; href: string | null }>;
export type KeyType = "concept" | "about" | "plan" | "flow" | "faq";

export enum KeyTypeEnum {
  Concept = "concept",
  About = "about",
  Plan = "plan",
  Flow = "flow",
  Faq = "faq",
}
export const Nav: Record<KeyType, { text: string; href: string }> = {
  [KeyTypeEnum.Concept]: {
    text: "コンセプト",
    href: `#${KeyTypeEnum.Concept}`,
  },
  [KeyTypeEnum.About]: {
    text: "シックスセンシズ京都とは",
    href: `#${KeyTypeEnum.About}`,
  },
  [KeyTypeEnum.Plan]: {
    text: "プラン",
    href: `#${KeyTypeEnum.Plan}`,
  },
  [KeyTypeEnum.Flow]: {
    text: "購入の流れ",
    href: `#${KeyTypeEnum.Flow}`,
  },
  [KeyTypeEnum.Faq]: {
    text: "よくあるご質問",
    href: `#${KeyTypeEnum.Faq}`,
  },
};
