import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Route } from "react-router-dom";
import HomePage from "./pages/home-page";
import ApplyPage from "./pages/apply-page";
import Root from "./pages/root-page";
import WalletRoot from "./pages/wallet-root-page";
import RegisterWalletPage from "./pages/register-wallet-page";
import RegistrationCompletedPage from "./pages/registration-completed-page.tsx";
import CheckinPage from "./pages/checkin-page.tsx";
import CheckinConfirmationPage from "./pages/checkin-confirmation-page.tsx";
import CheckinCompletedPage from "./pages/checkin-completed-page.tsx";
import CheckinCancelCompletedPage from "./pages/checkincancelcompleted-page.tsx";
import NftPage from "./pages/nft-page.tsx";
import ErrorPage from "./pages/error-page.tsx";
import { NftLoader } from "./loader/NftLoader.tsx";
import { CheckinLoader } from "./loader/CheckinLoader.tsx";
import { CheckinConfirmationLoader } from "./loader/CheckinConfirmationLoader.tsx";
import { RegisterWalletLoader } from "./loader/RegisterWalletLoader.tsx";
import { MyPageLoader } from "./loader/MyPageLoader.tsx";
import TagManager from "react-gtm-module";
import { ReactNode, useEffect, useState } from "react";
import MyPage from "./pages/my-page";
import { WagmiProvider } from "wagmi";
import { config } from "./constants/chain.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MyPageRoot from "./pages/mypage-root-page";
import NotFoundPage from "./pages/notfound-page";
import useLoginStore from "./store/useLoginStore.ts";
import "./api/handleRequestConfig.ts";

interface ProtectedRouteProps {
  children: ReactNode;
}
function LoginProtectedRoute({ children }: ProtectedRouteProps) {
  const { isLogin } = useLoginStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  if (!isLogin) {
    return null; // またはローディングインジケーターを表示
  }

  return <>{children}</>;
}

function AppComponent() {
  const tagManagerArgs = {
    gtmId: "GTM-NH4VWQ5J",
  };
  TagManager.initialize(tagManagerArgs);

  const [isReady, setIsReady] = useState(false);
  const prepare = async () => {
    if (import.meta.env.DEV && import.meta.env.VITE_APP_ENV === "local") {
      const { worker } = await import("../src/mocks/browser");
      await worker.start();
    }
  };

  useEffect(() => {
    prepare().then(() => setIsReady(true));
  }, []);

  if (!isReady) {
    return <></>;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<Root />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/apply" element={<ApplyPage />} />
        </Route>
        <Route element={<WalletRoot />}>
          <Route
            path="/wallet/register"
            element={<RegisterWalletPage />}
            loader={RegisterWalletLoader}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/wallet/registration-completed"
            element={<RegistrationCompletedPage />}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route element={<MyPageRoot />}>
          <Route
            path="/mypage"
            element={
              <LoginProtectedRoute>
                <MyPage />
              </LoginProtectedRoute>
            }
            loader={MyPageLoader}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/nft/:contractAddress/:tokenId"
            element={
              <LoginProtectedRoute>
                <NftPage />
              </LoginProtectedRoute>
            }
            loader={NftLoader}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/checkin/:contractAddress/:tokenId"
            element={
              <LoginProtectedRoute>
                <CheckinPage />
              </LoginProtectedRoute>
            }
            loader={CheckinLoader}
            errorElement={<ErrorPage />}
          />
          <Route
            path="checkin/confirmation/:contractAddress/:tokenId"
            element={
              <LoginProtectedRoute>
                <CheckinConfirmationPage />
              </LoginProtectedRoute>
            }
            loader={CheckinConfirmationLoader}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route path="checkin/completed" element={<CheckinCompletedPage />} />
        <Route
          path="checkin/cancel-completed"
          element={<CheckinCancelCompletedPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
}

function App() {
  const queryClient = new QueryClient();
  return (
    <RecoilRoot>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <AppComponent />
        </QueryClientProvider>
      </WagmiProvider>
    </RecoilRoot>
  );
}

export default App;
