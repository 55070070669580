import { atom, useRecoilState, useResetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const loginState = atom<boolean>({
  key: "loginState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

const useLoginStore = () => {
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const resetLogin = useResetRecoilState(loginState);

  return {
    isLogin,
    setIsLogin,
    resetLogin,
  };
};

export default useLoginStore;
