// 各環境に対応する設定ファイルを静的にインポート
import configLocal from "../config/hashwallet-config.local.ts";
import configDev from "../config/hashwallet-config.dev.ts";
import configStaging from "../config/hashwallet-config.stg.ts";
import configProduction from "../config/hashwallet-config.prod.ts";

function useHashWalletConfig() {
  const VITE_APP_ENV = import.meta.env.VITE_APP_ENV || "local";

  // 環境変数に基づいて適切な設定オブジェクトを選択
  let appConfig;
  switch (VITE_APP_ENV) {
    case "dev":
      appConfig = configDev;
      break;
    case "stg":
      appConfig = configStaging;
      break;
    case "prod":
      appConfig = configProduction;
      break;
    default:
      appConfig = configLocal;
  }

  return appConfig;
}

export default useHashWalletConfig;
