import { HashWalletSDKConfig, KeyType } from "hashwallet-js";

// COPY START HashWallet設定ツールからコピー
const config: HashWalletSDKConfig = {
  envConfig: {
    userPoolId: "ap-northeast-1_d37YbFRSa",
    cognitoRegion: "ap-northeast-1",
    identityPoolId: "ap-northeast-1:9b17ebab-b67c-4c23-bbe5-734bf9c89cc8",
    domain: "wallet-signin.auth.ap-northeast-1.amazoncognito.com",
    fusionauthUrl:
      "https://hashwallet-dev.fusionauth.io/oauth2/logout?client_id=2441013c-2c54-4736-8fd7-6d5074a9bdd8",
    keycloakLogoutUrl:
      "https://keycloak.dev-main.hash-wallet.com/realms/hashwallet/protocol/openid-connect/logout?client_id=account",
    keyInfoList: [
      {
        type: KeyType.S3,
        info: {
          bucketName: "key-backup-hashport-wallet-test1",
        },
      },
      {
        type: KeyType.S3,
        info: {
          bucketName: "key-backup-hashport-wallet-test2",
        },
      },
    ],
    apiEndpoint: "https://g49w7nyba4.execute-api.ap-northeast-1.amazonaws.com",
  },
  clientId: "it5jirvumee3asuot5si275k9",
  redirectUri: "https://dev.wealth-mngt-nft.com",
};
// COPY END HashWallet設定ツールからコピー

export default config;
