import { useState } from "react";

function useToggle(initialState = false) {
  const [isOpened, setIsOpened] = useState(initialState);

  const toggleOpened = () => {
    setIsOpened(!isOpened);
  };

  return { isOpened, toggleOpened };
}

export default useToggle;
