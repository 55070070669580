import { useState } from "react";
import InputContainer from "../InputContainer/InputContainer";
import OnelineInput, { Icon } from "../OneLineInput/OneLineInput";
import { requiredValidator, Validator } from "../../utils/validators";

interface Props {
  title: string;
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  isOption?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  subtext?: string;
  disabled?: boolean;
  maxlength?: number;
  hasLngOption?: boolean;
  rightIcon?: Icon;
  tooltipContent?: string;
  validators?: Validator[];
  onUpdateErrMessage?: (value: string) => string | undefined;
}
export default function CustomInput({
  title,
  value,
  setValue,
  placeholder = "",
  isOption = false,
  isRequired = false,
  isHidden = false,
  subtext = "",
  disabled = false,
  maxlength,
  hasLngOption = false,
  rightIcon,
  tooltipContent,
  validators = [],
  onUpdateErrMessage,
}: Props) {
  const [errMessage, setErrMessage] = useState("");
  const actualValidators = isRequired
    ? [requiredValidator, ...validators]
    : validators;

  return (
    <InputContainer
      title={title}
      subtext={subtext}
      errSubtext={errMessage}
      isOption={isOption}
      isRequired={isRequired}
      disabled={disabled}
      hasLngOption={hasLngOption}
      tooltipContent={tooltipContent}
    >
      <OnelineInput
        rightIcon={rightIcon}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        disabled={disabled}
        maxlength={maxlength}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        validators={actualValidators}
        isHidden={isHidden}
        onUpdateErrMessage={onUpdateErrMessage}
      />
    </InputContainer>
  );
}
