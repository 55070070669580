// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./MvSwiper.scss";
import HeadingAnimation from "../HeadingAnimation/HeadingAnimation";
import SixsensesLogo from "../../assets/sixsenses_logo_pc.svg?react";
import CopyPC from "../../assets/copy_pc.svg?react";
import SubCopy from "../../assets/subcopy_pc.svg?react";
import CopySP from "../../assets/copy_sp.svg?react";

export type MySwiperProps = {
  isMobile: boolean;
};
export function CopyAreaPC() {
  return (
    <>
      <HeadingAnimation>
        <SixsensesLogo />
      </HeadingAnimation>
      <HeadingAnimation>
        <CopyPC />
      </HeadingAnimation>
      <HeadingAnimation>
        <SubCopy />
      </HeadingAnimation>
    </>
  );
}
export function CopyAreaSP() {
  return (
    <>
      <HeadingAnimation>
        <SixsensesLogo />
      </HeadingAnimation>
      <HeadingAnimation>
        <CopySP />
      </HeadingAnimation>
      <HeadingAnimation>
        <SubCopy />
      </HeadingAnimation>
    </>
  );
}
export default function MvSwiper({ isMobile }: MySwiperProps) {
  return (
    <div className="mv-swiper">
      <div className="copy-container">
        {isMobile ? <CopyAreaSP /> : <CopyAreaPC />}
      </div>
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        loop={true}
        autoplay={{ delay: 3000 }}
      >
        <SwiperSlide>
          <div className="slide1 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide2 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide3 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide4 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide5 swiper-img"></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
