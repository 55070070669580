import { createConnector } from "wagmi";
import { Address } from "viem";
import { ethers } from "ethers";
import { palette, provider } from "../constants/chain";

hashwalletConnector.type = "hashwalletConnector" as const;
export function hashwalletConnector() {
  return createConnector(() => ({
    id: "hashwalletSDK",
    name: "HashWallet",
    type: hashwalletConnector.type,
    async setup() {},
    async connect() {
      try {
        const walletAddress = JSON.parse(
          localStorage.getItem("recoil-persist") ?? ""
        ).walletAddressState;
        let accounts: Address[] = [walletAddress as Address];
        return { accounts, chainId: palette.id };
      } catch (error) {
        throw error;
      }
    },
    async disconnect(): Promise<void> {
      localStorage.removeItem("wagmi.store");
    },
    isConnected() {
      return true;
    },
    async getAccounts() {
      const walletAddress = JSON.parse(
        localStorage.getItem("recoil-persist") ?? ""
      ).walletAddressState;
      let accounts: Address[] = [walletAddress as Address];
      return accounts;
    },
    async getChainId() {
      return palette.id;
    },
    async getProvider(): Promise<ethers.providers.JsonRpcProvider> {
      return provider;
    },
    async isAuthorized() {
      return true;
    },
    onAccountsChanged(callback) {
      console.log(callback, "onAccountsChanged");
    },
    onChainChanged(callback) {
      console.log(callback, "onChainChanged");
    },
    onConnect(callback) {
      console.log(callback, "onConnect");
    },
    onDisconnect(callback) {
      console.log(callback, "onDisconnect");
    },
  }));
}
