import { Outlet } from "react-router-dom";
import usePageTracking from "../hooks/usePageTracking";

export default function WalletRootPage() {
  usePageTracking();

  return (
    <>
      <Outlet /> {/* 子ルートがレンダリングされる場所 */}
    </>
  );
}
