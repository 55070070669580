export const ExchangeAbi = [
  {
    "inputs": [],
    "name": "AlreadyAllowlisted",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "AlreadyAllowlistedForContract",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "BuyCurrecyCodesEmpty",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "BuyerDidNotMatch",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "cryptoAmount",
        "type": "uint256"
      }
    ],
    "name": "CryptoAmountHigherThanUserIntent",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "CryptoAmountZero",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "CurrencyAddressSetForFiat",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "CurrencyCodeEmpty",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "CurrencyNotAllowlisted",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "finishAt",
        "type": "uint256"
      }
    ],
    "name": "FinishAtTooEarly",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidCurrencyType",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "duration",
        "type": "uint256"
      }
    ],
    "name": "InvalidDuration",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NFTNotApproved",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NoCurrencyFound",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NoSellOrderFound",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotAllowlistedForTheContract",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotAuthrizedToCancelSell",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotNFTOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "lastUpdatedAt",
        "type": "uint256"
      }
    ],
    "name": "OutdatedPriceFeedData",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PriceDoesNotMatch",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      }
    ],
    "name": "PriceOutOfRange",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "SaleFinished",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "startAt",
        "type": "uint256"
      }
    ],
    "name": "StartAtTooOld",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TokenHasNoCode",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UnacceptableCurrencyForSale",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UnacceptableTokenForSale",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "AddedDefaultCurrency",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "AllowlistedCurrency",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "AllowlistedCurrencyForContract",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "seller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "CanceledSell",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "DeletedCurrencyFromAllowlistForContract",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "DeletedDefaultCurrency",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "seller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string[]",
        "name": "buyCurrencyCodes",
        "type": "string[]"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "startAt",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "finishAt",
        "type": "uint256"
      }
    ],
    "name": "EditedSell",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "seller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "currencyCode",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "cryptoAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "buyer",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      }
    ],
    "name": "ExchangedWithCrypto",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "seller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "currencyCode",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "buyer",
        "type": "address"
      }
    ],
    "name": "ExchangedWithFiat",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "version",
        "type": "uint8"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "RemovedCurrencyFromAllowlist",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint16",
        "name": "percentage",
        "type": "uint16"
      }
    ],
    "name": "RemovedRoyalty",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "seller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string[]",
        "name": "buyCurrencyCodes",
        "type": "string[]"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "startAt",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "finishAt",
        "type": "uint256"
      }
    ],
    "name": "SellRequested",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "serviceProceedsReceiver",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint16",
        "name": "entireServiceProceedsPercent",
        "type": "uint16"
      }
    ],
    "name": "SetEntireServiceProceeds",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint16",
        "name": "percentage",
        "type": "uint16"
      }
    ],
    "name": "SetRoyalty",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "serviceProceedsReceiver",
        "type": "address"
      }
    ],
    "name": "SetServiceProceedsReceiver",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "currencies",
        "type": "tuple[]"
      }
    ],
    "name": "allowlistCurrenciesPerContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "allowlistCurrency",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "buyToken",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "cryptoAmount",
        "type": "uint256"
      }
    ],
    "name": "buyWithCrypto",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "buyer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "currencyCode",
        "type": "string"
      },
      {
        "internalType": "bytes",
        "name": "signature",
        "type": "bytes"
      }
    ],
    "name": "buyWithFiat",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "buyToken",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "calculateLatestCryptoAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "cancelSell",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "string[]",
        "name": "currencyCodes",
        "type": "string[]"
      }
    ],
    "name": "deleteCurrencyFromCurrencyAllowlistPerContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "currencyCode",
        "type": "string"
      }
    ],
    "name": "deleteCurrencyFromCurrencyAllowlistPerContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deleteDefaultCurrencies",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "currencies",
        "type": "tuple[]"
      }
    ],
    "name": "editCurrencyAllowlistPerContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "defaultCurrencies",
        "type": "tuple[]"
      }
    ],
    "name": "editDefaultCurrencies",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "string[]",
        "name": "buyCurrencyCodes",
        "type": "string[]"
      },
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "startAt",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "finishAt",
        "type": "uint256"
      }
    ],
    "name": "editSell",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "entireServiceProceedsPercent",
    "outputs": [
      {
        "internalType": "uint16",
        "name": "",
        "type": "uint16"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "getAccountNonce",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAllowlistedCurrencies",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "currencyCode",
        "type": "string"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "getBuyWithFiatStruct",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "seller",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "currencyCode",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "startAt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "finishAt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "nonce",
            "type": "uint256"
          }
        ],
        "internalType": "struct IExchangeV2.BuyWithFiat",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getCryptoCurrencyType",
    "outputs": [
      {
        "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string[]",
        "name": "codes",
        "type": "string[]"
      }
    ],
    "name": "getCurrencies",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "code",
        "type": "string"
      }
    ],
    "name": "getCurrency",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      }
    ],
    "name": "getCurrencyAllowlistPerContract",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getDefaultCurrencies",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getFiatCurrencyType",
    "outputs": [
      {
        "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      }
    ],
    "name": "getRoyalty",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
          },
          {
            "internalType": "uint16",
            "name": "percentage",
            "type": "uint16"
          }
        ],
        "internalType": "struct Royalty.RoyaltyInfo",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getSellOrder",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "seller",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "sellToken",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "sellTokenId",
            "type": "uint256"
          },
          {
            "internalType": "string[]",
            "name": "buyCurrencyCodes",
            "type": "string[]"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "startAt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "finishAt",
            "type": "uint256"
          }
        ],
        "internalType": "struct IExchangeV2.Order",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "currencies",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency[]",
        "name": "defaultCurrencies",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "_serviceProceedsReceiver",
        "type": "address"
      },
      {
        "internalType": "uint16",
        "name": "_serviceProceedsPercent",
        "type": "uint16"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "code",
            "type": "string"
          },
          {
            "internalType": "enum ICurrencyAllowlist.CurrencyTypes",
            "name": "currencyType",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "currencyAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "priceFeedAddress",
            "type": "address"
          }
        ],
        "internalType": "struct ICurrencyAllowlist.Currency",
        "name": "currency",
        "type": "tuple"
      }
    ],
    "name": "removeCurrencyFromAllowlist",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      }
    ],
    "name": "removeRoyalty",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "string[]",
        "name": "buyCurrencyCodes",
        "type": "string[]"
      },
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "startAt",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "finishAt",
        "type": "uint256"
      }
    ],
    "name": "sellRequest",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "serviceProceedsReceiver",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
          },
          {
            "internalType": "uint16",
            "name": "percentage",
            "type": "uint16"
          }
        ],
        "internalType": "struct Royalty.RoyaltyArg[]",
        "name": "bulkRoyalty",
        "type": "tuple[]"
      }
    ],
    "name": "setBulkRoyalty",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_serviceProceedsReceiver",
        "type": "address"
      },
      {
        "internalType": "uint16",
        "name": "_entireServiceProceedsPercent",
        "type": "uint16"
      }
    ],
    "name": "setEntireServiceProceeds",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
          },
          {
            "internalType": "uint16",
            "name": "percentage",
            "type": "uint16"
          }
        ],
        "internalType": "struct Royalty.RoyaltyArg",
        "name": "royalty",
        "type": "tuple"
      }
    ],
    "name": "setRoyalty",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_serviceProceedsReceiver",
        "type": "address"
      }
    ],
    "name": "setServiceProceedsReceiver",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
