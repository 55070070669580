import "./NoMemberships.scss";

export function NoImage() {
  return (
    <div className="no-image">
      <span>
        現在お持ちのMEMBERSHIPは
        <br />
        ございません
      </span>
    </div>
  );
}

export default function NoMemberships() {
  return (
    <div className="mymemberships">
      <div className="spw375h667px-spw375h667px">
        <span className="spw375h667px-text12">
          <span>保有NFT</span>
        </span>
        <div className="spw375h667px-group121">
          <div className="spw375h667px-frame114">
            <NoImage />
          </div>
        </div>
      </div>
    </div>
  );
}
