import { fetchTokenUri, getMetadata } from "../loader/NftLoader";
import {
  Guests,
  GuestsApi,
  GuestsMetadata,
  ReservationType,
} from "../api/page/generated";
import { CHECKIN_CONTRACT_ADDRESS_SAI_LONG } from "../constants/contracts";

export const saveGuests = async (
  guests: Guests,
  contractAddress: string,
  tokenId: string,
  reservationType: ReservationType
) => {
  const tokenUri = await fetchTokenUri(contractAddress, tokenId);
  const metadata = await getMetadata(tokenUri);
  const guestsMetadata: GuestsMetadata = {
    issue_at: getIssueAt(metadata.attributes) ?? 0,
    hotel: getHotel(metadata.attributes) ?? "",
    plan: getPlan(metadata.attributes) ?? "",
    checkin_year: Number(getCheckinYear(metadata.attributes)),
    checkin_month: Number(getCheckinMonth(metadata.attributes)),
    checkin_day: Number(getCheckinDay(metadata.attributes)),
    checkin_number_of_nights:
      extractNumberFromString(getNumberOfNights(metadata.attributes) ?? "") ??
      -1,
  };
  await new GuestsApi().postReservation({
    guests: guests,
    metadata: guestsMetadata,
    reservation_type: reservationType,
  });
};

export const cancelReservation = async (
  contractAddress: string,
  tokenId: string
) => {
  await new GuestsApi().postCancelReservation({
    contract_address: contractAddress,
    token_id: Number(tokenId),
  });
};

// 改行文字（\n）を<br>要素に変換する関数
export const addLineBreaks = (text: string): JSX.Element[] => {
  return text.split("\n").map((line) => (
    <>
      {line}
      <br />
    </>
  ));
};

const getAttributeValue = (attributes: Attribute[], traitType: string) =>
  attributes.find((attr) => attr.trait_type === traitType)?.value;

export const getIssueDate = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "ISSUE DATE");

export const getHotel = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "HOTEL");

export const getPlan = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "PLAN");

export const getNumberOfNights = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "NUMBER OF NIGHTS");

export const getCheckinYear = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "CHECK IN YEAR");

export const getCheckinMonth = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "CHECK IN MONTH");

export const getCheckinDay = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "CHECK IN DAY");

export const getBalance = (attributes: Attribute[]) =>
  getAttributeValue(attributes, "BALANCE");

export const getCheckinDate = (attributes: Attribute[]) => {
  const year = getCheckinYear(attributes);
  const month = getCheckinMonth(attributes);
  const day = getCheckinDay(attributes);
  if (!year || !month || !day) {
    return "";
  }
  return `${year}.${month}.${day}`;
};

export const getCheckoutDate = (attributes: Attribute[]): string => {
  const numberOfNights =
    extractNumberFromString(getNumberOfNights(attributes) ?? "") ?? -1;
  const checkinDateStr = getCheckinDate(attributes);
  if (!checkinDateStr) {
    return "";
  }

  const [year, month, day] = checkinDateStr.split(".").map(Number);
  const checkinDate = new Date(year, month - 1, day);
  const checkoutDate = new Date(checkinDate);
  checkoutDate.setDate(checkinDate.getDate() + numberOfNights);

  const checkoutYear = checkoutDate.getFullYear();
  const checkoutMonth = String(checkoutDate.getMonth() + 1).padStart(2, "0");
  const checkoutDay = String(checkoutDate.getDate()).padStart(2, "0");

  return `${checkoutYear}.${checkoutMonth}.${checkoutDay}`;
};

export const getCheckinDateTime = (attributes: Attribute[]) => {
  const year = getCheckinYear(attributes);
  const month = getCheckinMonth(attributes);
  const day = getCheckinDay(attributes);
  if (!year || !month || !day) {
    return "";
  }
  const monthIndex = parseInt(month, 10) - 1;
  return new Date(Number(year), monthIndex, Number(day));
};

export const getIssueAt = (attributes: Attribute[]) => {
  const issueDate = getAttributeValue(attributes, "ISSUE DATE");
  if (!issueDate) {
    return null;
  }
  return new Date(issueDate).getTime();
};

export type Attribute = {
  trait_type: string;
  value: string;
};

export const extractNumberFromString = (input: string) => {
  const match = input.match(/\d+/);
  if (match) {
    return parseInt(match[0], 10);
  }
  return null;
};

export const getRoomType = (contractAddress: string) => {
  switch (contractAddress) {
    case CHECKIN_CONTRACT_ADDRESS_SAI_LONG:
      return "Deluxe King 42平米＋バルコニー10平米";
    default:
      return "Deluxe King 42平米＋バルコニー10平米";
  }
};

export const extractBalance = (nightsString: string) => {
  const match = nightsString.match(/(\d+)\s+times/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }
  return null;
};
