import { LoaderFunctionArgs } from "react-router-dom";

export type CheckinLoaderType = {
  contractAddress: string;
  tokenId: number;
};

export const CheckinLoader = ({
  params,
}: LoaderFunctionArgs): CheckinLoaderType => {
  console.log("CheckinLoader");
  console.log(params);
  if (!params.contractAddress) {
    throw new Error("contractAddress is required");
  }
  if (!params.tokenId) {
    throw new Error("tokenId is required");
  }
  return {
    contractAddress: params.contractAddress,
    tokenId: Number(params.tokenId),
  };
};
