// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "./WalletMvSwiper.scss";
import HeadingAnimation from "../HeadingAnimation/HeadingAnimation";
import SixsensesLogo2 from "../../assets/sixsenses_logo_pc2.svg?react";
import WealthLogo from "../../assets/logo.svg?react";
import CopyPC2 from "../../assets/copy_pc2.svg?react";
import SubCopy2 from "../../assets/subcopy_pc2.svg?react";

export function CopyRight() {
  return (
    <p className="copyright">
      &copy; Wealth Management, Inc & HashPort Inc. All rights reserved.
    </p>
  );
}
export function CopyAreaPC() {
  return (
    <>
      <HeadingAnimation>
        <SixsensesLogo2 />
      </HeadingAnimation>
      <HeadingAnimation>
        <CopyPC2 />
      </HeadingAnimation>
      <HeadingAnimation>
        <SubCopy2 />
      </HeadingAnimation>
    </>
  );
}
export default function WalletMvSwiper() {
  return (
    <div className="wallet-mv-swiper">
      <div className="logo-container">
        <WealthLogo />
      </div>
      <div className="copy-container">
        <CopyAreaPC />
      </div>
      <div className="copyright-container">
        <CopyRight />
      </div>
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 7000, // 7秒後に次のスライドへ
          disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
        }}
        speed={2000}
      >
        <SwiperSlide>
          <div className="slide1 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide2 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide3 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide4 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide5 swiper-img"></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
