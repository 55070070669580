import useWalletAddressStore from "../store/useWalletAddressStore";
import { AuthApi } from "../api/page/generated";
import useLoginStore from "../store/useLoginStore";

export const useAuth = () => {
  const authApi = new AuthApi();
  const { isLogin, setIsLogin, resetLogin } = useLoginStore();
  const { resetWalletAddress } = useWalletAddressStore();

  const getOtt = async () => {
    const res = await authApi.getOtt();
    if (res && res.data) {
      console.log(res.data.ott);
      return res.data.ott;
    }
    throw new Error("Ott is not found");
  };

  const signIn = async (wallet_address: string, signature: string) => {
    try {
      await authApi.postAccountSigninWalletAddress({
        message: wallet_address,
        signature: signature,
      });
      setIsLogin(true);
    } catch (error) {
      throw new Error("Sign in failed", { cause: error });
    }
  };

  const signOut = async () => {
    try {
      await authApi.postAccountSignout();
      resetLogin();
      resetWalletAddress();
    } catch (error) {
      throw new Error("Sign out failed", { cause: error });
    }
  };

  return {
    isLogin,
    getOtt,
    signIn,
    signOut,
  };
};
