export const mapApiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

export const mapPosition = {
  lat: 34.990934,
  lng: 135.7745793,
} as google.maps.LatLngLiteral;

export const mapStyle = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#f7f8f2",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dddddc",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.government",
    stylers: [{ visibility: "on" }], // 他のPOIは表示したい場合は、このように設定を追加します。
  },
];
