import { atom, useRecoilState } from "recoil";
import {
  Status,
  PopupAlert,
  Alert as AlertProps,
} from "../components/models/Alert";

export const popupArrayState = atom<PopupAlert[]>({
  key: "popupArrayState",
  default: [],
});

export const counterState = atom<number>({
  key: "counterState",
  default: 0,
});

const usePopupStore = () => {
  const [popupArray, setPopupArray] = useRecoilState(popupArrayState);
  const [counter, setCounter] = useRecoilState(counterState);

  const push = (alertProps: AlertProps) => {
    setPopupArray((prevPopupArray) => [
      ...prevPopupArray,
      {
        ...alertProps,
        id: counter,
      },
    ]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const remove = (id: number) => {
    setPopupArray((prevPopupArray) =>
      prevPopupArray.filter((p) => p.id !== id)
    );
  };

  return {
    popups: popupArray,
    push,
    remove,
    success: (msg: string, content?: string) => {
      push({
        status: Status.Success,
        title: msg,
        content: content ?? "",
        cta: false,
        autoRemove: true,
        closeable: true,
      });
    },
    error: (msg: string, content?: string) => {
      push({
        status: Status.Error,
        title: msg,
        content: content ?? "",
        cta: false,
        autoRemove: true,
        closeable: true,
      });
    },
    info: (msg: string, content?: string) => {
      push({
        status: Status.Info,
        title: msg,
        content: content ?? "",
        cta: false,
        autoRemove: true,
        closeable: true,
      });
    },
    warn: (msg: string, content?: string) => {
      push({
        status: Status.Warning,
        title: msg,
        content: content ?? "",
        cta: false,
        autoRemove: true,
        closeable: true,
      });
    },
    infoWithCta: (msg: string, content: string) => {
      push({
        status: Status.Info,
        title: msg,
        content: content,
        cta: true,
        autoRemove: true,
        closeable: true,
      });
    },
  };
};

export default usePopupStore;
