import { PLTPLACE_MYPAGE_URL } from "../constants/links";

export const redirectToApplyPage = () => {
  window.open("/apply", "_blank", "noopener");
};

export function convertToJapaneseDuration(
  englishDuration: string
): string | null {
  const match = englishDuration.match(/\d+/);
  if (match) {
    const nights = parseInt(match[0], 10);
    const days = nights + 1;
    return `${nights}泊${days}日`;
  }
  return null;
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月は0から始まるため、+1する
  return `${year}.${month < 10 ? "0" + month : month}.${date.getDate()}`;
};

export const abbreviateWalletAddress = (
  walletAddress: string,
  maxLength: number = 10
) => {
  if (walletAddress.length <= maxLength) {
    return walletAddress;
  } else {
    const prefixLength = Math.ceil((maxLength - 3) / 2);
    const suffixLength = maxLength - prefixLength - 3;
    return `${walletAddress.slice(0, prefixLength)}...${walletAddress.slice(
      -suffixLength
    )}`;
  }
};

// 郵便番号から住所を取得する
export const fetchAddressByZipCode = async (zipCode: string) => {
  const res = await fetch(
    `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`
  );
  const data = await res.json();
  if (data.results === null) {
    throw new Error("Invalid zip code");
  }
  return data.results[0];
};

export const navigateToPLTPlaceMyPage = () => {
  window.open(PLTPLACE_MYPAGE_URL, "_blank");
};

// クラス名を生成するヘルパー関数
export function formatClassName(status: string) {
  // 最初の文字を大文字からハイフン付き小文字に変換し、その後続く文字を処理
  return `status ${status.toLowerCase()}`;
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

/**
 * 郵便番号をフォーマットする関数
 * @param postalCode - 郵便番号
 * @returns - ハイフンを含むフォーマット済みの郵便番号
 */
export function formatPostalCode(postalCode: string): string {
  // 郵便番号がすでにハイフンを含んでいる場合、そのまま返す
  if (postalCode.includes("-")) {
    return postalCode;
  }

  // 郵便番号の長さが7桁でない場合、そのまま返す
  if (postalCode.length !== 7) {
    return postalCode;
  }

  // ハイフンを挿入してフォーマットする
  return postalCode.slice(0, 3) + "-" + postalCode.slice(3);
}
