import { ADMIN_WALLET_ADDRESS } from "../constants/contracts";
import { Attribute, getCheckinDateTime } from "./reservation";
import { DefaultApi, TransactionDetail } from "../api/explorer";
import { isEqualWalletAddress } from "../constants/chain";
import { ethers } from "ethers";

export enum Status {
  COMING_SOON = "COMING_SOON",
  DISTRIBUTED_PENDING_RIGHTS = "DISTRIBUTED_PENDING_RIGHTS",
  NEAR_DEADLINE = "NEAR_DEADLINE",
  RESERVED = "RESERVED",
  BOOKED = "BOOKED",
  BOOKED_GRAYED_OUT = "BOOKED_GRAYED_OUT",
  TRANSFERRED = "TRANSFERRED",
  EXPIRED = "EXPIRED",
}

const _isPastUser = async (
  contractAddress: string,
  tokenId: string,
  walletAddress: string
) => {
  const res = await new DefaultApi().nfttokentransactionsPost({
    Contract: contractAddress,
    Token: tokenId,
    PageNo: 0,
    PageSize: 1000,
  });
  const txHistory = res.data;
  const isPastUser =
    txHistory.TransactionDetails?.some((tx: TransactionDetail) => {
      return (
        tx.From !== undefined && isEqualWalletAddress(tx.From, walletAddress)
      );
    }) ?? false;
  return isPastUser;
};

export const fetchOwner = async (contractAddress: string, tokenId: string) => {
  const res = await new DefaultApi().nfttokeninfoPost({
    Contract: contractAddress,
    Token: tokenId,
  });
  return res.data.Owner;
};

export const isAvailable = (status: Status) => {
  if (
    status === Status.BOOKED_GRAYED_OUT ||
    status === Status.TRANSFERRED ||
    status === Status.EXPIRED
  ) {
    return false;
  }
  return true;
};

// * 		使用可能
// * 		＜Coming soon＞: エアドロップ未済のもの
// * 		＜-＞: エアドロップ済み、権利確定前のもの
// * 		＜期限間近＞: エアドロップ済み、権利確定前のもの（宿泊日に対して2週間を切ったもの）
// * 		＜予約中＞: エアドロップ済み、権利確定後のもの、且つキャンセル期限前のもの
// * 		＜予約済＞: エアドロップ済み、権利確定後のもの、且つキャンセル期限を過ぎたもの、且つ宿泊日より前のもの
// * 		使用不可
// * 		＜予約済（グレーアウト）＞:エアドロップ済み、権利確定後のもの、且つキャンセル期限を過ぎたもの、且つ宿泊日より後のもの
// * 		＜譲渡済＞: エアドロップ済み、2次流通等で他者に譲渡したもの
// * 		＜失効＞:エアドロップ済み、権利確定しておらず、且つキャンセル期限を過ぎたもの

export const judgeStatus = async (
  contractAddress: string,
  tokenId: string,
  attributes: Attribute[],
  walletAddress: string,
  owner: string
) => {
  // 現在の日時
  const now = new Date();

  // 宿泊日
  const checkinDate = getCheckinDateTime(attributes);
  // 宿泊日に対して2週間を切っている
  const isOverNearDeadline =
    checkinDate < new Date(new Date(now).setDate(now.getDate() + 14));

  // 宿泊日に対して1週間を切っている（キャンセル期間を過ぎている）
  const isOverDeadline =
    checkinDate < new Date(new Date(now).setDate(now.getDate() + 7));

  // 宿泊日より前
  const isBeforeCheckinDate = new Date(checkinDate) > new Date();

  // 運営がNFTを持っている
  const isOwnerAdmin = isEqualWalletAddress(owner, ADMIN_WALLET_ADDRESS);

  // ユーザーがNFTを持っている
  const isOwnerUser = isEqualWalletAddress(owner, walletAddress);

  // 0アドレスがNFTを持っている
  const isOwnerZero = isEqualWalletAddress(owner, ethers.constants.AddressZero);

  // 他のユーザーがNFTを持っている
  const isOwnerOther = !isOwnerUser && !isOwnerAdmin && !isOwnerZero;

  // 過去ユーザーが持っている
  const isPastUser = await _isPastUser(contractAddress, tokenId, walletAddress);

  // 過去運営が持っている
  const isPastAdmin = await _isPastUser(
    contractAddress,
    tokenId,
    ADMIN_WALLET_ADDRESS
  );

  // ＜期限間近＞: エアドロップ済み、権利確定前のもの（宿泊日に対して2週間を切ったもの）
  if (isOwnerUser && isOverNearDeadline && !isOverDeadline) {
    return Status.NEAR_DEADLINE;
  }

  // ＜予約中＞: エアドロップ済み、権利確定後のもの、且つキャンセル期限前のもの
  if (isOwnerAdmin && !isOverDeadline && isPastUser) {
    return Status.RESERVED;
  }

  // ＜予約済＞: エアドロップ済み、権利確定後のもの、且つキャンセル期限を過ぎたもの、且つ宿泊日より前のもの
  if (isOwnerAdmin && isOverDeadline && isPastUser && isBeforeCheckinDate) {
    return Status.BOOKED;
  }

  // ＜予約済（グレーアウト）＞:エアドロップ済み、権利確定後のもの、且つキャンセル期限を過ぎたもの、且つ宿泊日より後のもの
  if ((isOwnerAdmin || isOwnerZero) && isPastUser && isPastAdmin) {
    return Status.BOOKED_GRAYED_OUT;
  }

  // ＜失効＞:エアドロップ済み、権利確定しておらず、且つキャンセル期限を過ぎたもの
  if (isOwnerAdmin || isOwnerZero) {
    return Status.EXPIRED;
  }

  // ＜譲渡済＞: エアドロップ済み、2次流通等で他者に譲渡したもの
  if (isPastUser && isPastAdmin && isOwnerOther) {
    return Status.TRANSFERRED;
  }

  // ＜-＞: エアドロップ済み、権利確定前のもの
  if (isOwnerUser) {
    return Status.DISTRIBUTED_PENDING_RIGHTS;
  }

  return Status.COMING_SOON;
};
