export const COMMERCIAL_URL = "pdf/commercial-transactions.pdf";
export const NFT_TERMS_OF_USE_URL = "/pdf/nft-terms-of-service.pdf";
export const PAYMENT_SERVICES_ACT_URL = "/pdf/payment-services.pdf";
export const QUESTION_URL = "https://note.com/wealth_mngt_nft/n/nb66eff5cfb26";
export const CONTACT_URL =
  "mailto:info@wealth-mngt.com?subject=Wealth Management NFTお問い合わせ";
export const WEALTH_COMPANY_URL =
  "https://www.wealth-mngt.com/company/profile.html";
export const WEALTH_PRIVACY_POLICY_URL =
  "https://www.wealth-mngt.com/privacypolicy.html";
export const HASHPORT_PRIVACY_POLICY_URL =
  "https://tokenarchitect.hashport.io/privacy";
export const WEALTH_URL = "https://www.wealth-mngt.com/";
export const HASHPORT_URL = "https://hashport.io/";
export const PLTPLACE_MYPAGE_URL =
  import.meta.env.VITE_PLTPLACE_URL + "/mypage";
