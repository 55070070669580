import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // GTMにページビューイベントを送信
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: location.pathname + location.search,
      },
    });
  }, [location]); // locationが変更されるたびにこのeffectを実行
}
export default usePageTracking;
