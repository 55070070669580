export type Validator = (v: string) => string;

export const requiredValidator: Validator = (text) => {
  if (text === "") {
    return "このフィールドは必須です。";
  }
  return "";
};

export const emailValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (
    !/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
      text
    )
  ) {
    return "正しいメールアドレスを入力してください。";
  }
  return "";
};

export const passwordValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (!/^.{8,}$/.test(text)) {
    return "8文字以上のパスワードを入力してください。";
  }
  if (!/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(text)) {
    return "文字、数字、および記号を含めてください。";
  }
  return "";
};

export const urlValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (
    !/[-a-zA-Z0-9@:%._#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_~#?&//=]*)/.test(
      text
    )
  ) {
    return "無効なURLです。適切なURLパターンを入力してください。";
  }
  return "";
};

export const twitterUrlValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (
    !/(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/.test(text)
  ) {
    return "正しいTwitter URLを入力してください。";
  }
  return "";
};

export const twitterIdValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (!/^[[a-zA-Z0-9_]+$/.test(text)) {
    return "無効なTwitter IDです。適切なTwitter IDパターンを入力してください。";
  }
  return "";
};

export const discordUrlValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (
    !/(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/.test(
      text
    )
  ) {
    return "無効なDiscord URLです。適切なDiscord URLパターンを入力してください。";
  }
  return "";
};

export const walletAddressValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (!/^0x[a-fA-F0-9]{40}$/.test(text)) {
    return "無効なウォレットアドレスです。適切なウォレットアドレスを入力してください。";
  }
  return "";
};

export const ipAddressValidator: Validator = (text) => {
  if (text === "") {
    return "";
  }
  if (
    !/^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
      text
    )
  ) {
    return "無効なIPアドレスです。適切なIPアドレスパターンを入力してください。";
  }
  return "";
};

export const validateJapanesePostalCode: Validator = (postalCode: string) => {
  const postalCodeRegex = /^\d{3}-\d{4}$/;
  if (!postalCodeRegex.test(postalCode)) {
    return "有効な郵便番号を入力してください。";
  }
  return "";
};

export const validateKatakana: Validator = (text: string) => {
  const katakanaRegex = /^[ァ-ヶー]+$/;
  if (!katakanaRegex.test(text)) {
    return "カタカナのみ入力してください。";
  }
  return "";
};

export const validateJapanesePhoneNumber: Validator = (phoneNumber: string) => {
  const phoneNumberRegex = /^0\d{1,4}-\d{1,4}-\d{4}$/;
  if (!phoneNumberRegex.test(phoneNumber)) {
    return "有効な電話番号を入力してください。";
  }
  return "";
};
