import MvSwiper from "../components/MvSwiper/MvSwiper";
import "../styles/home.scss";
import Concept from "../components/Concept/Concept";
import Benefit from "../components/Benefit/Benefit";
import About from "../components/About/About";
import AboutCarousel from "../components/AboutCarousel/AboutCarousel";
import AboutSP from "../components/AboutSP/AboutSP";
import { useMediaQuery } from "react-responsive";
import AboutBottom from "../components/AboutBottom/AboutBottom";
import MembershipNFT from "../components/MembershipNFT/MembershipNFT";
import UsageFlow from "../components/UsageFlow/UsageFlow";
import Question from "../components/Question/Question";
import { KeyTypeEnum } from "../constants/nav";

export default function HomePage() {
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  return (
    <div className="home">
      <div className="swiper-wrapper">
        <MvSwiper isMobile={isMobile} />
      </div>
      <div id={KeyTypeEnum.Concept}>
        <Concept />
      </div>
      <Benefit />
      <div id={KeyTypeEnum.About}>
        {isMobile ? (
          <AboutSP />
        ) : (
          <>
            <About />
            <AboutCarousel />
            <AboutBottom />
          </>
        )}
      </div>
      <div id={KeyTypeEnum.Plan}>
        <MembershipNFT />
      </div>
      <div id={KeyTypeEnum.Flow}>
        <UsageFlow />
      </div>
      <div id={KeyTypeEnum.Faq}>
        <Question />
      </div>
    </div>
  );
}
