import { HashWalletSDKConfig, KeyType } from "hashwallet-js";

// COPY START HashWallet設定ツールからコピー
const config: HashWalletSDKConfig = {
  envConfig: {
    userPoolId: "ap-northeast-1_4UL6iurcX",
    cognitoRegion: "ap-northeast-1",
    identityPoolId: "ap-northeast-1:48a83b11-957d-471b-a40f-21419566b3f9",
    domain: "wallet-users-signin.auth.ap-northeast-1.amazoncognito.com",
    fusionauthUrl:
      "https://hashwallet-prod.fusionauth.io/oauth2/logout?client_id=65c1ad68-dfdc-4a1b-9fe6-40a940d2b339",
    keycloakLogoutUrl:
      "https://keycloak.prod-main.hash-wallet.com/realms/hashwallet/protocol/openid-connect/logout?client_id=account",
    keyInfoList: [
      {
        type: KeyType.S3,
        info: {
          bucketName: "key-backup-hashport-wallet-prod1",
        },
      },
      {
        type: KeyType.S3,
        info: {
          bucketName: "key-backup-hashport-wallet-prod2",
        },
      },
    ],
    apiEndpoint: "https://6md8mrbxr8.execute-api.ap-northeast-1.amazonaws.com",
  },
  clientId: "4um4in2g3sfi07alcvcrbaoos2",
  redirectUri: "https://wealth-mngt-nft.com",
};
// COPY END HashWallet設定ツールからコピー

export default config;
