// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "./CheckinMvSwiper.scss";

export default function CheckinMvSwiper() {
  return (
    <div className="checkin-mv-swiper">
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 7000, // 7秒後に次のスライドへ
          disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
        }}
        speed={2000}
      >
        <SwiperSlide>
          <div className="slide1 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide2 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide3 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide4 swiper-img"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide5 swiper-img"></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
