import { useState } from "react";
import InputContainer from "../InputContainer/InputContainer";
import { Icon } from "../OneLineInput/OneLineInput";
import { requiredValidator, Validator } from "../../utils/validators";
import TextArea from "../TextArea/TextArea";

interface Props {
  title: string;
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  isOption?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  subtext?: string;
  disabled?: boolean;
  maxlength?: number;
  hasLngOption?: boolean;
  rightIcon?: Icon;
  tooltipContent?: string;
  validators?: Validator[];
  onUpdateErrMessage?: (value: string) => string | undefined;
}
export default function CustomTextArea({
  title,
  value,
  setValue,
  placeholder = "",
  isOption = false,
  isRequired = false,
  subtext = "",
  disabled = false,
  maxlength,
  hasLngOption = false,
  tooltipContent,
  validators = [],
}: Props) {
  const [errMessage, setErrMessage] = useState("");
  const actualValidators = isRequired
    ? [requiredValidator, ...validators]
    : validators;

  return (
    <InputContainer
      title={title}
      subtext={subtext}
      errSubtext={errMessage}
      isOption={isOption}
      isRequired={isRequired}
      disabled={disabled}
      hasLngOption={hasLngOption}
      tooltipContent={tooltipContent}
    >
      <TextArea
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        disabled={disabled}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        validators={actualValidators}
        maxlength={maxlength}
      />
    </InputContainer>
  );
}
